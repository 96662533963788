import * as React from 'react';
import { useMemo, useCallback, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton,
    Collapse, Typography, Box, Paper, Stack, Tooltip, Grid, Divider, } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp, LocalShipping } from "@mui/icons-material";
import { PatientVisit, Client, Visit, DocumentInfo, CustomFields, VisitStatus } from '../../../models/core';
import { DateTime } from 'luxon';
import GridForm, { GridFormItem } from '../../GridForm';
import { AutocompleteField, DateTimeField, EnumField, TextF } from '../../fields';
import { EmailOutcomes, MailOutcomes, PhoneOutcomes, PhoneTypeNames, RefusalReasons, ScheduledFailedReasons, TextOutcomes, VisitStatuses } from '../../../utils/enums';
import PersonIcon from '@mui/icons-material/Person';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import { UseChangeManager, useChangeManager } from '../../../hooks/useChangeManager';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import { API, ListResponse } from '../../../utils/Api';
import SaveIcon from '@mui/icons-material/Save';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DeleteIcon from '@mui/icons-material/Delete';
import useSnackbar from '../../../hooks/useSnackbar';
import AlertSnack from '../../ErrorSnack';
import { PatientConditionPanelData } from '../../ConditionInfoEditor';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { useAuth } from '../../../hooks/useAuth';

export enum PatientViewTableActions {
  OpenPatientEditor = 0,
  OpenVisitPage = 1,
  CopyMeasures = 2,
  CopyAddress = 3,
  AssignPatient = 4,
}

interface PatientsViewTableProps {
    patients: PatientVisit[];
    client?: Client;
    outreachReps?:string[];
    visitCM?:UseChangeManager<Omit<Visit, '_id'>>;
    contentWidth?:string;
    onAction?: (action:PatientViewTableActions, patient:PatientVisit, visit:Visit[])=>void;
}

type PatientRow = {
    name:string;
    dob:string;
    gender:string;
    phone:string;
    phone2:string;
    guardianPhone:string;
    email:string;
    guardianEmail:string;
    patient:PatientVisit;
}

type PatientColumn = {
    field:(keyof Omit<PatientRow, 'patient'>);
    name:string;
    width:number;
    sort?:string|undefined;
    editable?:boolean|undefined;
}

const PatientViewTable = ({ patients, visitCM, client, outreachReps, contentWidth, onAction }: PatientsViewTableProps): JSX.Element => {
    const MAX_REQUEST_RETRIES = 3;
    const [expandedRow, setExpandedRow] = useState<string | null>(null);
    const [sortConfig, setSortConfig] = useState<{ key: string; direction: "asc" | "desc" } | null>(null);
    const [visitCache, setVisitCache] = useState<Record<string, Visit[]>>({});//Object.fromEntries(patients.map((e) => [e._id, [e.visit]])));
    const [expandedVisits, setExpandedVisits] = useState<Visit[]>([]);
    const [activeVisit, setActiveVisit] = useState<Visit>();
    const [measureCache, setMeasureCache] = useState<Record<string, string>>({});
    const { showSuccess:snackSuccess, showError:snackError, ...snackProps } = useSnackbar();

    const useCM = useChangeManager<Omit<Visit, '_id'>>();
    const { remove: _dv, changes:changedVisits, contain: _cv,
      hasField, setField:_setVisitsField, getField:getVisitsField } = visitCM || useCM;

    const changedVisitsRef = React.useRef(changedVisits);
    const timersRef = React.useRef<Map<string, NodeJS.Timeout>>(new Map());
    const requiresUpdateRef = React.useRef<Map<string, boolean>>(new Map());
    const activePatientID = React.useRef(expandedRow);

    const auth = useAuth();
    
    const columns:PatientColumn[] = useMemo(() => [
        {field: 'name', name: ' Name', width: 150},
        {field: 'dob', name: 'DoB', width: 100, editable:false},
        {field: 'gender', name: 'Gender', width: 75, editable:false},
        {field: 'phone', name: 'Phone', width: 100, editable:false},
        {field: 'phone2', name: 'Alt Phone', width: 100, editable:false},
        {field: 'guardianPhone', name: 'Guardian Phone', width: 100, editable:false},
        {field: 'email', name: 'Email', width: 100, editable:false},
        {field: 'guardianEmail', name: 'Guardian Email', width: 100, editable:false},
    ], [])

    const columnOrder:(keyof Omit<PatientRow, 'patient'>)[] = useMemo(() => columns.flatMap((v) => v.field), [columns]);

    const rows:PatientRow[] = useMemo(() => (
        patients.map(p => ({
            name:`${p.lastName}, ${p.firstName}`.toUpperCase(),
            dob: p.dob || '',
            gender: p.gender || '',
            phone: p.phone || '',
            phone2: p.phone2 || '',
            guardianPhone: p.guardianPhone || '',
            email: p.email || '',
            guardianEmail: p.guardianEmail || '',
            patient:p
        }))
    ), [patients]);

    const saveVisit = useCallback(async (newVisit:Visit) => {
      if(!newVisit) {
        return;
      }

      let retryAttempt = 0;
      let result = null;
      requiresUpdateRef.current.set(newVisit._id,false);
      while(retryAttempt < MAX_REQUEST_RETRIES) {
        try {
          result = await API.updateVisit(newVisit);
          if(result) {
            console.log(result)
            const keys = Object.keys(result)
            if(keys.includes('acknowledged') && keys.includes('modifiedCount')) {
              const modified = Object.entries(result).find((v) => v[0] == 'modifiedCount' && v[1] != 0)?.length || -1;
              if(modified > 0) {
                snackSuccess(`Updated Visit: ${newVisit._id}`);                
              } else {
                snackError(new Error(`Failed to Save: ${newVisit._id}`));
              }
            }
          }
        }catch(e) {
          console.error(e);
          snackError(new Error(`Failed to Save: ${newVisit._id}`));
          retryAttempt += 1;
          await new Promise(resolve => setTimeout(resolve, 500))
        }
        break;
      }
    }, [snackSuccess, snackError])

    async function loadVisits(clientId: string, patientId: string, onComplete?:(visits:Visit[])=>void): Promise<ListResponse<Visit>> {
      let result = null;
      if(patientId && client) {
        let retryAttempt = 0;
        while(retryAttempt < MAX_REQUEST_RETRIES) {
          try {
            result = await API.getVisits(clientId, patientId, [['status', 'Active']]);
          }catch(e) {
            console.error(e);
            retryAttempt += 1;
            await new Promise(resolve => setTimeout(resolve, 500))
          }

          if(result) {
            //console.log(result);
            onComplete && onComplete(result?.items || []);
          }
          break;
        }
      }
      return result || { count:0, items:[] }
    }

    const loadExpandedVisits = useCallback((patientId:string) =>  {
      setActiveVisit(patients.find((e) => e._id == patientId)?.visit);
      const cache = visitCache[patientId];
      if(cache) {
        setExpandedVisits(cache);
      } else if(client?._id) {
        loadVisits(client?._id, patientId, (visits) => {
          setVisitCache((prev) => ({
            ...prev,
            [patientId]: visits || []
          }));
          setExpandedVisits(visits);
        });
      }
    }, [loadVisits, visitCache, client?._id, patients])

    React.useEffect(() => {
      changedVisitsRef.current = changedVisits;
    }, [changedVisits]);

    const updateQueue = useCallback((id: string) => {
      const visitChanges = changedVisitsRef.current[id] || {};
      console.log("Processing ID:", id, "Changes:", visitChanges);

      const patientVisit = patients.find((v) => v.visit._id === id)?.visit;
      if (patientVisit && Object.entries(visitChanges).length !== 0) {
        saveVisit({
          ...patientVisit,
          ...visitChanges,
        });
      }
    }, [patients, saveVisit]);

    React.useEffect(() => {
      requiresUpdateRef.current.forEach((value, key) => {
        if (timersRef.current.has(key)) {
          clearTimeout(timersRef.current.get(key));
        }
        if(value && changedVisits[key]) {
          const newTimer = setTimeout(() => {
            updateQueue(key);
          }, 5000);
          timersRef.current.set(key, newTimer);
        }
      });

      return () => {
        const refs = timersRef.current;
        refs.forEach((timer) => { clearTimeout(timer); });
        refs.clear();
      };
    }, [changedVisits, requiresUpdateRef, updateQueue]);

    const setVisitsField = useCallback((id: string, updates: Partial<Omit<Visit, "_id">>) => {
      _setVisitsField(id, updates);
      requiresUpdateRef.current.set(id,true);
    }, [_setVisitsField, requiresUpdateRef] );

    const handleSort = (key: string) => {
        setSortConfig((prev) => {
          if (prev?.key === key && prev?.direction === "asc") {
            return { key, direction: "desc" };
          }
          return { key, direction: "asc" };
        });
      };
    
      const sortedRows = React.useMemo(() => {
        if (!sortConfig) return rows;
    
        return [...rows].sort((a, b) => {
          const aValue = a[sortConfig.key as keyof typeof a] || '';
          const bValue = b[sortConfig.key as keyof typeof b] || '';
    
          if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
          if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
          return 0;
        });
      }, [rows, sortConfig]);
    
      const toggleRow = (id: string) => {
        const isSame = expandedRow === id;
        setExpandedRow(isSame ? null : id);
        activePatientID.current = isSame ? null : id;
        if(!isSame) {
          loadExpandedVisits(id);
        }
      };

      const onActionCallback = useCallback((action:PatientViewTableActions, patient:PatientVisit, visit:Visit[]) => {
        onAction && onAction(action, patient, visit);
      }, [onAction]);

      const copyAddressString = React.useCallback((patient:PatientVisit) => {
        navigator.clipboard.writeText(`${patient.address} ${patient.address2}, ${patient.city}, ${patient.state}. ${patient.zip}`);
        snackSuccess('Address Copied');
      },[snackSuccess])

      const copyMeasureString = React.useCallback(async (patient:PatientVisit) => {
        function copyConditionData(contentName:string, content:PatientConditionPanelData[]) {
          let copyData:string = contentName;
          content?.forEach((it) => { copyData = copyData + `\n${it.name}: ${it.value}`; });
          return copyData;
        }

        let copyString = '';
        if(measureCache[patient._id]) {
          copyString = measureCache[patient._id] || '';
        } else {
          const trackableList = await API.getPatientTrackables(patient?.clientId, patient?._id);
          const conditionsList:Record<string, PatientConditionPanelData[]> = {};
          trackableList?.items?.forEach((it) => {
              it?.trackable?.forEach((item) => {
                const id = item.id || (Date.now().toString() + 'a' + conditionsList[item.type].length).toString(); 
                  if(!conditionsList[item.type]) {
                      conditionsList[item.type] = [];
                  }
                  conditionsList[item.type].push({ _id:id, visitId:it.visitId, name:item.name || '', value:item.value || '', info:item.info })
              })
          });
          Object.entries(conditionsList).forEach((e) => { copyString += copyConditionData(e[0]+'s',e[1]) + '\n' });
          setMeasureCache((prev) => ({
            ...prev,
            [patient._id]: copyString || ''
          }))
        }
        
        navigator.clipboard.writeText(copyString);
        snackSuccess('Measures Copied');

      }, [measureCache, setMeasureCache, snackSuccess]);

      const createScheduleTable = useCallback((patient:PatientVisit, activeVisit:Visit,
        get:(id:string, key:(keyof Omit<Visit, '_id'>)) => string | number | boolean | DocumentInfo | CustomFields | string[] | undefined,
        set:(id:string, key:(Partial<Omit<Visit, '_id'>>)) => void,
        has:(id:string, key:(keyof Omit<Visit, '_id'>)) => boolean) => {

        if(activeVisit.patientId != patient._id) {
          return <></>;
        }

        function updateAppointment(id:string, change:{[key:string]:string|undefined}) {
          //console.log(change);
          set(id, change);
        }

        function getCurrentValue(id:string, key:(keyof Omit<Visit, '_id'>), defaultValue:string|undefined) {
          return has(id, key) ? get(id, key) : defaultValue;
        }

        const visit = activeVisit; //patient.visit;
        const visitId = visit._id;
        const maxScheduleAttempts = 4;
        const fieldvalues = [
          [getCurrentValue(visitId, 'scheduled1', visit?.scheduled1), getCurrentValue(visitId, 'scheduledReason1', visit?.scheduledReason1), 1],
          [getCurrentValue(visitId, 'scheduled2', visit?.scheduled2), getCurrentValue(visitId, 'scheduledReason2', visit?.scheduledReason2), 2],
          [getCurrentValue(visitId, 'scheduled3', visit?.scheduled3), getCurrentValue(visitId, 'scheduledReason3', visit?.scheduledReason3), 3],
          [getCurrentValue(visitId, 'scheduled4', visit?.scheduled4), getCurrentValue(visitId, 'scheduledReason4', visit?.scheduledReason4), 4]
        ];
        let values = fieldvalues.filter(e => (e[0] && e[0] != '') || (e[1] && e[1] != ''));
        if(values.find((e, i) => (i+1) != e[2])) {
          values = fieldvalues;
        }

        function deleteScheduleAttempt(visit:Visit, index:number) {
          let aIdx = 0;
          const mv = Array.from({ length:maxScheduleAttempts}, (_, i) => i);
          mv.forEach((_,i) => {
            const s = fieldvalues[i][0];
            const sr = fieldvalues[i][1];
            if(((s && s != '') || (sr && sr != '')) && i != index) {
              updateAppointment(visitId,{[`scheduled${aIdx+1}`]:fieldvalues[i][0] as string});
              updateAppointment(visitId,{[`scheduledReason${aIdx+1}`]:fieldvalues[i][1] as string});
              aIdx += 1;
            }
          });
          mv.forEach((_,i) => {
            if(i >= aIdx) {
              updateAppointment(visitId,{[`scheduled${i+1}`]:''});
              updateAppointment(visitId,{[`scheduledReason${i+1}`]:''});
            }
          });
        }
        
        return (<>
        <Stack direction={'row'}>
          <Typography variant='body1' alignContent={'center'}>Appointments: </Typography>
          <Tooltip title='Create Scheduled Appointment'>
            <span>
              <IconButton aria-label="create appointment" size="small" disabled={values.length >= maxScheduleAttempts}
                onClick={() => updateAppointment(visitId, {[`scheduled${(fieldvalues[values.length][2] as number)}`]:' '})}>
                  <CalendarMonthIcon/>
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
          <Grid container padding='2px 8px 4px 8px'>
              {
                values.map((m, i) => {
                  return (
                    <GridFormItem key={'sh' + i} xs={12} padding='4px 0px 4px 0px'>
                      <Stack direction={'row'} padding='4px 0px 4px 0px'>
                      <GridFormItem xs={2.5} padding='0px 4px 0px 4px'>
                        <DateTimeField name={'Schedule Date ' + (m[2] as number)} value={DateTime.fromISO(m[0] as string || '')}
                          onChange={
                            (_,v) => 
                              v && updateAppointment(visitId, {[`scheduled${(m[2] as number)}`]:(v.toISO() || '')})
                          }/>
                        </GridFormItem>
                        <GridFormItem xs={8} padding='0px 4px 0px 4px'>
                        <EnumField name='Did not happen reason' value={m[1] as string || ''} options={ScheduledFailedReasons} 
                            onChange={(_, v) => updateAppointment(visitId, {[`scheduledReason${(m[2] as number)}`]:(v || '')})}/>
                        </GridFormItem>
                        <GridFormItem xs={1.5} padding='0px 4px 0px 4px'>
                          <Tooltip title='Delete'>
                            <IconButton onClick={() => deleteScheduleAttempt(visit, i)}>
                              <DeleteIcon/>
                            </IconButton>
                          </Tooltip>
                        </GridFormItem>
                      </Stack>
                      </GridFormItem>
                    )
                  })
                }
          </Grid>
        </>)
      },[]);

      const createOutreachTable = useCallback((patient:PatientVisit, activeVisit:Visit,
        get:(id:string, key:(keyof Omit<Visit, '_id'>)) => string | number | boolean | DocumentInfo | CustomFields | string[] | undefined,
        set:(id:string, key:(Partial<Omit<Visit, '_id'>>)) => void) => {

        if(activeVisit.patientId != patient._id) {
          return <></>;
        }

        function updateVisit(id:string, change:{[key:string]:string|undefined|CustomFields|string[]|number}) {
          set(id, change);
        }

        const visit = activeVisit; //patient.visit;
        const visitId = visit._id;
        const cfEntries = Object.entries(visit.customFields || {});
        const updatedCFEntries = Object.entries(get(visitId, 'customFields') || []);

        function getEntryIf(key:string) {
          const has = updatedCFEntries.find((n) => n[0]  == key);
          if(has) {
            return has[1];
          }
          return cfEntries.find((n) => n[0] == key)?.[1]
        }

        function createOutreachList(cadt:string, rat:string, oat:string) {
          return Array.from({ length:1}, (_, i) => i + 1).map((_,index) => {
            const date = getEntryIf(cadt);
            const rep = getEntryIf(rat) ;
            const out = getEntryIf(oat);
            return {index:(index + 1), date:date, rep:rep, outcome:out};
          }) || [];
        }

        const maxCallAttempts = client?.layout?.options?.numCallAttempts || 6;
        function deleteCallAttempt(index:number, values:{index: number; date: string; type: string; rep: string; outcome: string;}[]) {
          const revalue = values.filter((_,i) => i != index);

          const fill = Array.from({ length:maxCallAttempts}, (_,i) => {
            const v = i < revalue.length ? revalue[i] : undefined;
            return {
              [`Call Attempt ${i+1}`]:v ? v.date : undefined,
              [`Phone Type ${i+1}`]:v ? v.type : undefined,
              [`Call Rep Name ${i+1}`]:v ? v.rep : undefined,
              [`Outcome of Phone Attempt ${i+1}`]:v ? v.outcome : undefined
            }
          }).reduce((prev, curr) => { Object.assign(prev, curr); return prev;}, {});

          updateVisit(visitId, 
            {
              'customFields':{
                ...visit.customFields,
                ...cfData,
                ...(fill as CustomFields)
              }
            });
        }

        function deleteOutreach(type:'Mail'|'Text'|'Email', index:number, values:{index: number; date: string; rep: string; outcome: string;}[]) {
          const revalue = values.filter((_,i) => i != index);

          const fill = Array.from({ length:1}, (_,i) => {
            const v = i < revalue.length ? revalue[i] : undefined;
            return {
              [`Date of ${type} Attempt`]:v ? v.date : undefined,
              [`${type} Rep Name`]:v ? v.rep : undefined,
              [`Outcome of ${type} Attempt`]:v ? v.outcome : undefined
            }
          }).reduce((prev, curr) => { Object.assign(prev, curr); return prev;}, {});

          updateVisit(visitId, 
            {
              'customFields':{
                ...visit.customFields,
                ...cfData,
                ...(fill as CustomFields)
              }
            });
        }

        const phoneOutreach = Array.from({ length:maxCallAttempts}, (_, i) => i + 1).map((_,index) => {
          const cadt = `Call Attempt ${index+1}`;
          const tat = `Phone Type ${index+1}`;
          const rat = `Call Rep Name ${index+1}`;
          const oat = `Outcome of Phone Attempt ${index+1}`;
          const date = getEntryIf(cadt);
          const type = getEntryIf(tat)
          const rep = getEntryIf(rat);
          const out = getEntryIf(oat);
          return {index:(index + 1), date:date, type:type, rep:rep, outcome:out};
        }) || [];

        const emailOutreach = createOutreachList('Date of Email Attempt', 'Email Rep Name', 'Outcome of Email Attempt');
        const smsOutreach = createOutreachList('Date of Text Attempt', 'Text Rep Name', 'Outcome of Text Attempt');
        const mailOutreach = createOutreachList('Date of Mail Attempt', 'Mail Rep Name', 'Outcome of Mail Attempt');
        
        let values = phoneOutreach.filter(f => (f.date && f.date != '') || (f.type && f.type != '') || (f.rep && f.rep != '') || (f.outcome && f.outcome != '')) || [];
        if(values.find((e, i) => (i+1) != e.index)) {
          values = phoneOutreach;
        }

        let emailValues = emailOutreach.filter(f => (f.date && f.date != '') || (f.rep && f.rep != '') || (f.outcome && f.outcome != '')) || [];
        if(emailValues.find((e, i) => (i+1) != e.index)) {
          emailValues = emailOutreach;
        }

        let smsValues = smsOutreach.filter(f => (f.date && f.date != '') || (f.rep && f.rep != '') || (f.outcome && f.outcome != '')) || [];
        if(smsValues.find((e, i) => (i+1) != e.index)) {
          smsValues = smsOutreach;
        }

        let mailValues = mailOutreach.filter(f => (f.date && f.date != '') || (f.rep && f.rep != '') || (f.outcome && f.outcome != '')) || [];
        if(mailValues.find((e, i) => (i+1) != e.index)) {
          mailValues = mailOutreach;
        }

        const cfDataT = get(visitId, 'customFields') || {};
        const cfData = (typeof cfDataT === 'object' ? cfDataT : {});
        return (<>
          <Stack direction={'row'}>
            <Typography variant='body1' alignContent={'center'}>Contact Attempts: </Typography>
            <Tooltip title='Add Phone Attempt'>
              <span>
              <IconButton disabled={values.length >= maxCallAttempts} onClick={() => {
                updateVisit(visitId, 
                  {
                    'customFields':{
                      ...visit.customFields,
                      ...cfData,
                      [`Call Attempt ${values.length+1}`]:DateTime.now().toISO(),
                      [`Phone Type ${values.length+1}`]:'',
                      [`Call Rep Name ${values.length+1}`]:auth?.user.name || '',
                      [`Outcome of Phone Attempt ${values.length+1}`]:'',
                    }
                  });
                  updateVisit(visitId, {'numCalls':(values.length + 1)})
                  updateVisit(visitId, {'numContacts':(smsValues.length + emailValues.length  + 1 + values.length + mailValues.length)})
              }}><CallIcon/></IconButton>
              </span>
            </Tooltip>
            <Tooltip title='Add Email Attempt'>
            <span>
              <IconButton disabled={emailValues.length >= 1} onClick={() => {
                updateVisit(visitId, 
                  {
                    'customFields':{
                      ...visit.customFields,
                      ...cfData,
                      [`Date of Email Attempt`]:DateTime.now().toISO(),
                      [`Email Rep Name`]:auth?.user.name || '',
                      [`Outcome of SMS Attempt`]:'',
                    }
                  });
                  updateVisit(visitId, {'numEmails':(emailValues.length + 1)})
                  updateVisit(visitId, {'numContacts':(smsValues.length + emailValues.length  + 1 + values.length + mailValues.length)})
              }}><EmailIcon/></IconButton>
              </span>
            </Tooltip>
            <Tooltip title='Add Mail Attempt'>
              <span>
              <IconButton disabled={smsValues.length >= 1} onClick={() => {
                updateVisit(visitId, 
                  {
                    'customFields':{
                      ...visit.customFields,
                      ...cfData,
                      [`Date of Text Attempt`]:DateTime.now().toISO(),
                      [`Text Rep Name`]:auth?.user.name || '',
                      [`Outcome of Text Attempt`]:'',
                    }
                  });
                  updateVisit(visitId, {'numSMS':(smsValues.length + 1)})
                  updateVisit(visitId, {'numContacts':(smsValues.length + emailValues.length  + 1 + values.length + mailValues.length)})
              }}><SmsIcon/></IconButton>
              </span>
            </Tooltip>

            <Tooltip title='Add Mail Attempt'>
              <span>
              <IconButton disabled={mailValues.length >= 1} onClick={() => {
                updateVisit(visitId, 
                  {
                    'customFields':{
                      ...visit.customFields,
                      ...cfData,
                      [`Date of Mail Attempt`]:DateTime.now().toISO(),
                      [`Mail Rep Name`]:auth?.user.name || '',
                      [`Outcome of Mail Attempt`]:'',
                    }
                  });
                  updateVisit(visitId, {'numMails':(mailValues.length + 1)})
                  updateVisit(visitId, {'numContacts':(smsValues.length + emailValues.length  + 1 + values.length + mailValues.length)})
              }}><LocalShipping/></IconButton>
              </span>
            </Tooltip>
          </Stack>
          <Grid container padding='2px 8px 4px 8px'>
              {
                values.length != 0 && <Typography variant='subtitle2' alignContent={'center'}>Call Attempts<Divider/></Typography>
              }
              {
                values.map((m, i) => {
                  const cadt = `Call Attempt ${m.index}`;
                  const tat = `Phone Type ${m.index}`;
                  const rat = `Call Rep Name ${m.index}`;
                  const oat = `Outcome of Phone Attempt ${m.index}`;
                  return (
                    <GridFormItem key={'sh' + i} xs={12} padding='4px 0px 4px 0px'>
                      <Stack direction={'row'} padding='4px 0px 4px 0px'>
                      <GridFormItem xs={2.5} padding='0px 4px 0px 4px'>
                        <DateTimeField name={`Call Attempt ${m.index} Date`} value={DateTime.fromISO(m.date || '')}
                          onChange={
                            (_,v) => v && updateVisit(visitId, {'customFields':{...visit.customFields,...cfData, [cadt]:(v.toISO() || '')}})
                          }/>
                        </GridFormItem>
                        <GridFormItem xs={3} padding='0px 4px 0px 4px'>
                          <EnumField name='Type' value={m.type || ''} options={PhoneTypeNames} clearLabel='Clear Field'
                            onChange={
                              (_,v) => updateVisit(visitId, {'customFields':{...visit.customFields,...cfData, [tat]:v || ''}})
                            }/>
                        </GridFormItem>
                        <GridFormItem xs={3} padding='0px 4px 0px 4px'>
                          <AutocompleteField name='Scheduler' value={m.rep} options={outreachReps || ['']} 
                            onChange={
                              (_,v) => { if(v && v != m.rep) { updateVisit(visitId, {'customFields':{...visit.customFields,...cfData, [rat]:v || ''}}) }}
                            }/>
                        </GridFormItem>
                        <GridFormItem xs={3} padding='0px 4px 0px 4px'>
                          <EnumField name='Outcome' value={m.outcome || ''} options={PhoneOutcomes} clearLabel='Clear Field'
                            onChange={
                              (_,v) => v && updateVisit(visitId, {'customFields':{...visit.customFields,...cfData, [oat]:v || ''}})
                            }/>
                        </GridFormItem>
                        <GridFormItem xs={0.5} padding='0px 4px 0px 4px'>
                          <Tooltip title='Delete'>
                            <IconButton onClick={() => deleteCallAttempt(i, values)}>
                              <DeleteIcon/>
                            </IconButton>
                          </Tooltip>
                        </GridFormItem>
                        </Stack>
                      </GridFormItem>
                    )
                  })
                }

                {
                  emailValues.length != 0 && <Typography variant='subtitle2' alignContent={'center'}>Email Attempts<Divider/></Typography>
                }
                {
                  emailValues.map((m, i) => {
                    const cadt = `Date of Email Attempt`;
                    const rat = `Email Rep Name`;
                    const oat = `Outcome of Email Attempt`;
                    return (
                      <GridFormItem key={'sh' + i} xs={12} padding='4px 0px 4px 0px'>
                        <Stack direction={'row'} padding='4px 0px 4px 0px'>
                        <GridFormItem xs={2.5} padding='0px 4px 0px 4px'>
                          <DateTimeField name={`Email Attempt ${m.index} Date`} value={DateTime.fromISO(m.date || '')}
                            onChange={
                              (_,v) => v && updateVisit(visitId, {'customFields':{...visit.customFields,...cfData, [cadt]:(v.toISO() || '')}})
                            }/>
                          </GridFormItem>
                          <GridFormItem xs={3} padding='0px 4px 0px 4px'>
                            <AutocompleteField name='Scheduler' value={m.rep} options={outreachReps || ['']}  
                              onChange={
                                (_,v) => { if(v && v != m.rep) { updateVisit(visitId, {'customFields':{...visit.customFields,...cfData, [rat]:v || ''}}) }}
                              }/>
                          </GridFormItem>
                          <GridFormItem xs={3} padding='0px 4px 0px 4px'>
                            <EnumField name='Outcome' value={m.outcome || ''} options={EmailOutcomes} clearLabel='Clear Field'
                              onChange={
                                (_,v) => v && updateVisit(visitId, {'customFields':{...visit.customFields,...cfData, [oat]:v || ''}})
                              }/>
                          </GridFormItem>
                          <GridFormItem xs={3.5} padding='0px 4px 0px 4px'>
                            <Tooltip title='Delete'>
                              <IconButton onClick={() => deleteOutreach('Email', i, emailValues)}>
                                  <DeleteIcon/>
                              </IconButton>
                            </Tooltip>
                          </GridFormItem>
                          </Stack>
                        </GridFormItem>
                      )
                    })
                }

                {
                  smsValues.length != 0 && <Typography variant='subtitle2' alignContent={'center'}>SMS Attempts<Divider/></Typography>
                }
                {
                  smsValues.map((m, i) => {
                    const cadt = `Date of Text Attempt`;
                    const rat = `Text Rep Name`;
                    const oat = `Outcome of Text Attempt`;
                    return (
                      <GridFormItem key={'sh' + i} xs={12} padding='4px 0px 4px 0px'>
                        <Stack direction={'row'} padding='4px 0px 4px 0px'>
                        <GridFormItem xs={2.5} padding='0px 4px 0px 4px'>
                          <DateTimeField name={`SMS Attempt ${m.index} Date`} value={DateTime.fromISO(m.date || '')}
                            onChange={
                              (_,v) => v && updateVisit(visitId, {'customFields':{...visit.customFields,...cfData, [cadt]:(v.toISO() || '')}})
                            }/>
                          </GridFormItem>
                          <GridFormItem xs={3} padding='0px 4px 0px 4px'>
                            <AutocompleteField name='Scheduler' value={m.rep} options={outreachReps || ['']}
                              onChange={
                                (_,v) => { if(v && v != m.rep) { updateVisit(visitId, {'customFields':{...visit.customFields,...cfData, [rat]:v || ''}}) }}
                              }/>
                          </GridFormItem>
                          <GridFormItem xs={3} padding='0px 4px 0px 4px'>
                            <EnumField name='Outcome' value={m.outcome || ''} options={TextOutcomes} clearLabel='Clear Field'
                              onChange={
                                (_,v) => v && updateVisit(visitId, {'customFields':{...visit.customFields,...cfData, [oat]:v || ''}})
                              }/>
                          </GridFormItem>
                          <GridFormItem xs={3.5} padding='0px 4px 0px 4px'>
                            <Tooltip title='Delete'>
                              <IconButton onClick={() => deleteOutreach('Text', i, smsValues)}>
                                  <DeleteIcon/>
                              </IconButton>
                            </Tooltip>
                          </GridFormItem>
                          </Stack>
                        </GridFormItem>
                      )
                    })
                }

                {
                  mailValues.length != 0 && <Typography variant='subtitle2' alignContent={'center'}>Mail Attempts<Divider/></Typography>
                }
                {
                  mailValues.map((m, i) => {
                    const cadt = `Date of Mail Attempt`;
                    const rat = `Mail Rep Name`;
                    const oat = `Outcome of Mail Attempt`;
                    return (
                      <GridFormItem key={'sh' + i} xs={12} padding='4px 0px 4px 0px'>
                        <Stack direction={'row'} padding='4px 0px 4px 0px'>
                        <GridFormItem xs={2.5} padding='0px 4px 0px 4px'>
                          <DateTimeField name={`Mail Attempt ${m.index} Date`} value={DateTime.fromISO(m.date || '')}
                            onChange={
                              (_,v) => v && updateVisit(visitId, {'customFields':{...visit.customFields,...cfData, [cadt]:(v.toISO() || '')}})
                            }/>
                          </GridFormItem>
                          <GridFormItem xs={3} padding='0px 4px 0px 4px'>
                            <AutocompleteField name='Scheduler' value={m.rep} options={outreachReps || ['']}
                              onChange={
                                (_,v) => { if(v && v != m.rep) { updateVisit(visitId, {'customFields':{...visit.customFields,...cfData, [rat]:v || ''}}) }}
                              }/>
                          </GridFormItem>
                          <GridFormItem xs={3} padding='0px 4px 0px 4px'>
                            <EnumField name='Outcome' value={m.outcome || ''} options={MailOutcomes} clearLabel='Clear Field'
                              onChange={
                                (_,v) => v && updateVisit(visitId, {'customFields':{...visit.customFields,...cfData, [oat]:v || ''}})
                              }/>
                          </GridFormItem>
                          <GridFormItem xs={3.5} padding='0px 4px 0px 4px'>
                            <Tooltip title='Delete'>
                              <IconButton onClick={() => deleteOutreach('Mail', i, mailValues)}>
                                  <DeleteIcon/>
                              </IconButton>
                            </Tooltip>
                          </GridFormItem>
                          </Stack>
                        </GridFormItem>
                      )
                    })
                }
          </Grid>
        </>)
      },[client, outreachReps, auth?.user.name]);


      const createVisitSelectField = useCallback((visit:Visit) => {
        const availableVisits = (expandedVisits && expandedVisits.length > 0) 
          ? expandedVisits.sort((a,b) => DateTime.fromISO(a.created ||'').toSeconds() - DateTime.fromISO(b.created || '').toSeconds()) : [visit]

        const visitTime = visit.created?.substring(0, visit.created?.indexOf('T')) || '';

        return (<EnumField name='Visit' value={visitTime}
          options={availableVisits.map((c) => c.created?.substring(0, c.created?.indexOf('T')) || '') || []}
          onChange={(_,v) => setActiveVisit(availableVisits.find((e) => e.created?.substring(0, e.created?.indexOf('T')) === v))} />)
      },[expandedVisits, setActiveVisit])

      const createStatusTable = useCallback((patient:PatientVisit, activeVisit:Visit,
        get:(id:string, key:(keyof Omit<Visit, '_id'>)) => string | number | boolean | DocumentInfo | CustomFields | string[] | undefined,
        set:(id:string, key:(Partial<Omit<Visit, '_id'>>)) => void,
        has:(id:string, key:(keyof Omit<Visit, '_id'>)) => boolean) => {

          if(activeVisit.patientId != patient._id) {
            return <></>;
          }

          const visit = activeVisit; //|| patient.visit;
          return (<GridForm>
            <GridFormItem xs={2}>
              { createVisitSelectField(visit || patient.visit) }
            </GridFormItem>
            <GridFormItem xs={2}>
              <EnumField name='Visit Status' value={get(visit._id, 'status') as string || visit.status || ''} options={VisitStatuses} 
                  onChange={(_,v) => set(visit._id, {'status':v as VisitStatus})}/>
            </GridFormItem>
            <GridFormItem xs={2}>
              <EnumField name='Refusal UTR Status' value={get(visit._id, 'refusalReason') as string || visit.refusalReason || ''} options={RefusalReasons}
                onChange={(_,v) => set(visit._id, {'refusalReason':v||undefined})}/>
            </GridFormItem>
            <GridFormItem xs={6}>
            <TextF name='Ziphy Comments' value={ has(visit._id, 'comments') ? get(visit._id, 'comments') as string : (visit.comments || '')}
            onChange={
                (_,v) => set(visit._id, {'comments':v||''})
              } fullWidth/>
            </GridFormItem>
          </GridForm>);
      },[createVisitSelectField]);

      function mergeObjectsInArrays(array1:Visit[], array2:Record<string,Partial<Visit>>) {        
        return array1.map((obj1) => {return { ...obj1, ...array2[obj1._id] }; });
      }
    
      return (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell/>
                {
                    columnOrder.map(colName => {
                        const col = columns.find((v) => v.field == colName);
                        const sorted = col && col.sort && col.sort !== '' ? col.sort : undefined;
                        return <TableCell  sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                                onClick={ sorted  ? () => handleSort(sorted) : undefined}
                                key={'k-' + colName}>
                                {col?.name + (sorted && sortConfig?.key === sorted && (sortConfig.direction === "asc" ? "▲" : "▼") || '')}
                        </TableCell>
                    })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRows.map((row) => (
                <React.Fragment key={row.patient._id}>
                  {/* Master Row */}
                  <TableRow>
                    <TableCell>
                        <Stack direction='row'>
                          <Tooltip title='Expand'>
                            <IconButton aria-label="expand row" size="small" onClick={() => toggleRow(row.patient._id)} >
                                {expandedRow === row.patient._id ? <KeyboardArrowUp /> : <KeyboardArrowDown/>}
                            </IconButton>
                          </Tooltip>
                          {
                            <Tooltip title='Open Profile Editor'>
                              <IconButton aria-label="expand row" size="small" onClick={() => 
                                  onActionCallback(PatientViewTableActions.OpenPatientEditor, row.patient,
                                    (visitCache[row.patient._id] ? mergeObjectsInArrays(visitCache[row.patient._id], changedVisits) : []) || [])
                                    }>
                                  <PersonIcon/></IconButton>
                            </Tooltip>
                          }
                          <Tooltip title='Copy Measures'>
                            <IconButton aria-label="expand row" size="small" onClick={() => {
                                  copyMeasureString(row.patient);
                                  onActionCallback(PatientViewTableActions.CopyMeasures, row.patient,visitCache[row.patient._id] || []);
                                }} >
                                <ContentCopyIcon/></IconButton>
                          </Tooltip>
                          <Tooltip title='Copy Address'>
                            <IconButton aria-label="expand row" size="small" onClick={() => {
                                  copyAddressString(row.patient);
                                  onActionCallback(PatientViewTableActions.CopyAddress, row.patient, visitCache[row.patient._id] || []);
                                }} >
                                <PersonPinCircleIcon/></IconButton>
                          </Tooltip>
                          <Tooltip title='Assign'>
                            <IconButton aria-label="expand row" size="small" onClick={() => {
                                  onActionCallback(PatientViewTableActions.AssignPatient, row.patient, visitCache[row.patient._id] || []);
                                }} >
                                <AssignmentIndIcon/></IconButton>
                          </Tooltip>
                          {
                            (visitCache[row.patient._id] || []).filter((v) => {
                              return requiresUpdateRef.current.get(v._id)}).length != 0
                                ? <Tooltip title='Changes Are Being Saved'><Box sx={{alignContent:'center'}}><SaveIcon/></Box></Tooltip>
                                : <Box sx={{alignContent:'center'}}><SaveIcon sx={{color:'transparent'}}/></Box>
                          }
                        </Stack>
                    </TableCell>

                    {
                        columnOrder.map(colName => <TableCell  sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }} key={colName}>{row[colName] ?? ''}</TableCell>)
                    }
                  </TableRow>
                  {/* Detail Row */}
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length}>
                      <Collapse in={expandedRow === row.patient._id} timeout="auto" unmountOnExit>
                        <Box margin={1} width={contentWidth || '100%'} border={'thin'}>
                          <Box margin={1}>
                          {
                            createStatusTable(row.patient, activeVisit || row.patient.visit, getVisitsField, setVisitsField, hasField)
                          }
                          </Box>
                          <Divider/>
                          <Box margin={1}>
                          {
                            createOutreachTable(row.patient, activeVisit || row.patient.visit, getVisitsField, setVisitsField)
                          }
                          </Box>
                          <Divider/>
                          <Box margin={1}>
                          {
                            createScheduleTable(row.patient, activeVisit || row.patient.visit, getVisitsField, setVisitsField, hasField)
                          }
                          </Box>
                          <Divider/>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
          <AlertSnack {...snackProps}/>
        </TableContainer>
      );
}

export default PatientViewTable;