import * as React from 'react';
import { useState } from 'react';
import { /*redirect, ActionFunctionArgs,*/ LoaderFunctionArgs, 
    Outlet, useLoaderData, useNavigate, useLocation } from 'react-router-dom';
import AppBarPage from '../components/AppBarPage';
import { Grid, IconButton, List, ListItemText, Menu, 
    MenuItem, Pagination, Tooltip, ListItemIcon, Paper, Box, Typography, ListItem, ListItemButton, Divider,  Stack,
    createTheme} from '@mui/material';
import { API } from '../utils/Api';
import { Client, ClusterJob, Job, JobStatus, Patient, Plan, ScheduleJob, Visit } from '../models/core';
import { DateTime } from 'luxon';
import useSearchParamsDict from '../hooks/useSearchParamsDict';
import useDialog from '../hooks/useDialog';
import { useAuth } from '../hooks/useAuth';
import PatientEditor from '../components/PatientEditor';
import SessionStorage from '../utils/SessionStorage';
import { analytics } from '../utils/analytics/zipAnalytics';
import { /*PersonAdd,*/ MoreVert, Schedule as ScheduleIcon, 
    Workspaces as WorkspacesIcon, /*AssignmentInd as AssignmentIcon*/ } from '@mui/icons-material';
import ClusterDialog from '../components/ClusterDialog';
import ScheduleDialog from '../components/ScheduleDialog';
import AssignmentDialog from '../components/AssignmentDialog'
import { PatientListItem } from '../components/PatientListItem';
import { AuthData } from '../models/ziphy';
import { useLoading } from '../hooks/useLoading';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { EnumField } from '../components/fields';
import UCMEditor from '../components/UCMEditor';


interface PatientClusterPoint{
    lid:number;
    r:number;
    ids:string[];
}
interface PatientClusterData {
    points:PatientClusterPoint[];
    totalPatients:number;
}

export interface PatientListViewData {
    clientId:string;
}
interface PatientListClusterViewData extends PatientListViewData {
    pointIds:string[];
    clusterId:string;
    clusterData:PatientClusterData;
    activePatientIds:string[];
}

export type PatientLoaderData = [Client, (Patient & { visit?: Visit })[], number, Plan[], Job[], PatientListViewData];

const scrollListStyle = createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          ':root': {
            '--scrollbar-width': '8px',
            '--scrollbar-thumb-color': '#888',
            '--scrollbar-thumb-hover-color': '#555',
            '--scrollbar-track-color': '#f1f1f1',
          },
        },
      },
    },
});


export const loader = async ({ params, request }: LoaderFunctionArgs): Promise<PatientLoaderData> => {

    let clientId = params.clientId;
    if (!clientId) {
        const match = request.url.match(/clients\/(?<clientId>\w+)\//);
        clientId = match && match.groups && match.groups['clientId'] || '';

        if (!clientId) {
            throw Error('Missing clientId param');
        }
    }

    if(!API.token || API.token == '') {
        const authItem = window.localStorage.getItem('auth');
        const auth = authItem ? (JSON.parse(authItem) as AuthData) : null;
        API.token = auth?.accessToken?.value || '';
    }

    const searchParams = new URL(request.url).searchParams;

    const ssCID = new SessionStorage<string>('clientId');
    const ssCData = new SessionStorage<Client>('clientData');
    const ssPlanData = new SessionStorage<Plan[]>('planData');
    const ssAssignData = new SessionStorage<string[]>('assignData');
    const ssJobsMeta = new SessionStorage<Job[]>('jobMeta');
    const ssClusterID = new SessionStorage<string>('clusterId');

    let client:Client|null = null;
    let plans:Plan[]|null = null;
    let jobs:Job[]|null = null;
    let clusterId:string|null = null;
    if(ssCID.get() === clientId) {
        client = ssCData.get();
        plans = ssPlanData.get();
        jobs = ssJobsMeta.get();
        clusterId = ssClusterID.get();
        if(!jobs?.find(e => (e.clientId == clientId)) || clusterId != searchParams.get('clusterId')) {
            jobs = null;
            clusterId = null;
            ssJobsMeta.remove();
            ssClusterID.remove();
        }
    }

    if(!client) {
        client = await API.getClient(clientId).catch((error) => {
            ssCData.remove();
            ssCID.remove();
            ssPlanData.remove();
            ssJobsMeta.remove();
            ssAssignData.remove();
            ssClusterID.remove();

            console.error('Error in client:', error);
            analytics.error('api_error', { message:error }, true);
            throw error;
        });
        ssCID.set(clientId);
        ssCData.set(client);
    }

    if(!plans) {
        plans = (await API.getPlans(clientId).catch((error) => {
            ssCData.remove();
            ssCID.remove();
            ssPlanData.remove();
            ssJobsMeta.remove();
            ssAssignData.remove();
            ssClusterID.remove();

            console.error('Error in plans:', error);
            analytics.error('api_error', { message:error }, true);
            throw error;
        }))?.items || [];

        ssPlanData.set(plans);
    }

    if(!jobs) {
        jobs = (await API.getJobsMeta(clientId, undefined, ).catch((error) => {
            ssCData.remove();
            ssCID.remove();
            ssPlanData.remove();
            ssJobsMeta.remove();
            ssAssignData.remove();
            ssClusterID.remove();

            console.error('Error in jobs:', error);
            analytics.error('api_error', { message:error }, true);
            throw error;
        }))?.items || [];

        ssJobsMeta.set(jobs);
    }
    let patientData:PatientClusterData|null = null;

    if(!clusterId) {
        clusterId = searchParams.get('clusterId');
        const clusterJobs = jobs?.filter(j => j.type == 'cluster');
        if(!clusterId && clusterJobs?.length != 0) {
            clusterId = clusterJobs[0]._id;
        }
    }

    if(clusterId) {
        const activeJob = await API.getJob(clientId, clusterId).catch((error) => {
            // TODO

            console.error('Error in jobs:', error);
            analytics.error('api_error', { message:error }, true);
            throw error;
        }) as ClusterJob;
        ssClusterID.set(clusterId);

        if(activeJob && activeJob.status == JobStatus.SUCCESS) {
            let totalPatients = 0;
            // Prepare the data for caching and patient retrieval
            const clusterPoints = (activeJob.result.clusters.map((cluster, i) => {

                const pids = cluster.points.map(point => point?.patient?._id || '');
                const points = { lid:i, r: Number.parseFloat(cluster?.radius?.value?.toPrecision(4) ?? '0'),  ids:pids || [] }
                totalPatients += pids.length;
                
                return points;
            }) || []).sort((e) => e.r);
            patientData = { points:clusterPoints, totalPatients:totalPatients }
        }
    }

    const pointsList = searchParams.get('points')?.split('|') || [];
    const availableIds = (patientData?.points?.filter(p => pointsList?.length == 0 ? 
                true : pointsList?.includes(p.lid.toString())).flatMap(points => points.ids) || []);
    const ids = (availableIds.slice(0, 10) || []).join('|');

    const sortParam = searchParams.get('sort');
    const { items: patients, count:numPats } = ids?.length > 0 ?
        await API.getPatients(
            clientId,
            [['_id', ids]],
            10,
            10 * (Number.parseInt(searchParams.get('page') || '1', 10) - 1),
            (sortParam && JSON.parse(sortParam)) || []
        ).catch((error) => {
            console.error('Error in patients:', error);
            analytics.error('api_error', { message:error }, true);
            throw error;
        })
    : { items:[], count:0 };


    return [client, patients, availableIds?.length || 0, plans, jobs as Job[],
        { clientId:clientId, clusterId:clusterId, clusterData:patientData, activePatientIds:ids, pointIds:pointsList} as PatientListViewData];
}


const PatientsClustersPage = (): JSX.Element => {

    const [client, patients, count, plans, jobs, viewData] = useLoaderData() as PatientLoaderData;
    const clientId = React.useMemo(() => viewData?.clientId || '', [viewData]);
    
    const [searchParams, setSearchParams] = useSearchParamsDict<'page' | 'sort' | 'clusterId' | 'points'>();

    const [patientToEdit, setPatientToEdit] = useState<Patient | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const loading = useLoading();

    const page = React.useMemo(() => parseInt(searchParams.page || '1', 10), [searchParams]);
    
    const { open: peOpen, show: showPE, hide: hidePE } = useDialog();
    const { open: clOpen, show: showCl, hide: hideCL } = useDialog();
    const { open: a1dOpen, show: showA1D, hide: hideA1D } = useDialog();
    //const { open: aMdOpen, show: showAMD, hide: hideAMD } = useDialog();
    const { open: scOpen, show: showSc, hide: hideSc } = useDialog();
    
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [assignablePatient, setAssignablePatient] = useState<Patient|null>(null);
    const [hidePointList, setHidePointList] = useState<boolean>(false);
    const [ucmPage, setUCMPage] = useState<'patient'|'visits'>('patient');

    const auth = useAuth();
    React.useEffect(() => { loading.hideLoading(); }, [patients]);
    React.useEffect(() => { loading.hideLoading(); }, []);

    const onPageChange = React.useCallback((event: React.ChangeEvent<unknown>, value: number) => {
        setSearchParams({ page: value + '' })
    }, [setSearchParams]);

    const onMoreClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const onMenuClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const onClusterClick = React.useCallback(() => {
        showCl();
        setAnchorEl(null);
    }, [showCl]);

    const onScheduleClick = React.useCallback(() => {
        showSc()
        setAnchorEl(null);
    }, [showSc]);

    const onEdit = React.useCallback((patient: Patient) => {
        setPatientToEdit(patient);
        showPE();
    }, [showPE]);

    const onPatientSaved = React.useCallback(() => {
        navigate(location.pathname + location.search, { replace: true });
        hidePE();
    }, [hidePE, navigate, location]);

    const patientList = React.useMemo(() => {
            return (patients || []).map((patient) => (
                <PatientListItem
                    key={patient._id}
                    patient={patient}
                    visit={patient.visit || null}
                    onOpenVisits={
                        (p) => {
                            setUCMPage('visits');
                            onEdit(p);
                        }
                    }
                    onEdit={
                        (p) => {
                            setUCMPage('patient');
                            onEdit(p);
                        }
                    }
                    onAssign={(patient:Patient) => {
                            setAssignablePatient(patient);
                            showA1D();
                        }
                    }
                />
            ));
        },
        [patients, onEdit, setAssignablePatient, showA1D]
    );

    const assignConfirmSingleDialog = React.useMemo<JSX.Element>(() => {
        const onHandleAssignUser = async (value:string) => {

            if(assignablePatient == null) {
                console.error('Patient not set for assignment');
                return;
            }
    
            const ts = DateTime.now().toString();
            const assigner = auth?.user?.name || 'NA';
            const prevAssignment = assignablePatient?.assignment?.assignedTo || 'None';
            analytics.track('assign', { type:'single', patient:assignablePatient._id, to:value, by: assigner, date:ts, from:prevAssignment }, true);

            await API.updatePatient({ ...assignablePatient, ...
                { assignment: { assignedDate: ts, assignedTo: value, assignedBy: assigner } }
            });
            
            setSearchParams({ page: page + '' });
            hideA1D();
        };

        return <AssignmentDialog open={a1dOpen} showIfBusy={true} client={client} 
            defaultValue={ auth?.user?.name || undefined } onAssign={onHandleAssignUser} onClose={hideA1D}/>
        
    },[a1dOpen, hideA1D, client, assignablePatient, auth?.user?.name, setSearchParams, page]);

    const clusterFilterList = React.useMemo<JSX.Element>(() => {
        const clusterViewData = (viewData as PatientListClusterViewData);
        const allPoints = clusterViewData?.pointIds?.length == clusterViewData?.clusterData?.points?.length || false;
        const clusterItems = jobs?.filter(f => f.type == 'cluster').map(
            e => {
                    return <ListItem key={e._id}>
                        <Box display="flex" flexDirection="column" width="100%">
                        <ListItemButton disableRipple onClick={() => {
                                if(e._id != clusterViewData?.clusterId) {
                                    loading.showLoading();
                                    setSearchParams({clusterId:e._id})
                                    setHidePointList(false);
                                } else {
                                    setHidePointList(!hidePointList);
                                }
                            }
                        } sx={{backgroundColor:e._id == clusterViewData?.clusterId ? '#f5f5f5' : undefined}}>
                        <ListItemText
                            primary={<Typography component='div' sx={{ display: 'flex', justifyContent: 'start'}}>{e.name}</Typography>}
                            secondary={
                                <Typography component="span">
                                <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  <Typography>
                                    {'status: ' + e.status}
                                  </Typography>
                                  <Typography>
                                    {e._id === clusterViewData?.clusterId && !hidePointList ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
                                  </Typography>
                                </Stack>
                              </Typography>
                            }/>
                        </ListItemButton>
                        <Divider/>
                        {
                            (e._id === clusterViewData?.clusterId && e.status == JobStatus.SUCCESS && !hidePointList) ?
                            <Box>
                                <List dense>
                                    {
                                        <ListItem key={'l-all'}>
                                        <ListItemButton disableRipple sx={{paddingX:0.5, paddingY:0.5,
                                            backgroundColor:allPoints ? '#f5f5f5' : undefined
                                        }}
                                            onClick={() => {
                                                if(!allPoints) {
                                                    loading.showLoading();
                                                    setSearchParams({'points':clusterViewData?.clusterData?.points?.map(v => v.lid.toString())?.join('|') || '' })
                                                }
                                        }}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography fontSize={'10pt'} component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                                                        All
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography fontSize={'9pt'} component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                                                        {'Patients: ' + clusterViewData?.clusterData?.totalPatients}
                                                    </Typography>
                                                } />
                                            </ListItemButton>
                                            <Divider/>
                                        </ListItem>
                                    }
                                    {
                                        clusterViewData?.clusterData?.points?.map((e, i) => {
                                            return <ListItem key={'l-'+ i.toString()}>
                                            <ListItemButton disableRipple sx={{paddingX:0.5, paddingY:0.5,
                                                backgroundColor: !allPoints && clusterViewData?.pointIds?.includes(e.lid.toString())
                                                    ? '#f5f5f5' : undefined
                                            }}
                                                onClick={() => {
                                                    if(allPoints || !clusterViewData?.pointIds?.includes(e.lid.toString())) {
                                                        loading.showLoading();
                                                        setSearchParams({'points':e.lid.toString()})
                                                    }
                                                }}>
                                                <ListItemText
                                                    primary={
                                                        <Typography fontSize={'10pt'} component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                                                            {'Radius: ' + e.r + ' mi'}
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <Typography fontSize={'9pt'} component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                                                            {'Patients: ' + e.ids.length}
                                                        </Typography>
                                                    } />
                                                </ListItemButton>
                                                <Divider/>
                                            </ListItem>
                                        })
                                    }
                                </List>
                            </Box> : <></>
                        }
                    </Box>
                </ListItem>
            }
        );


        return <Grid item xs={12} padding='2px 8px 0 8px'>
            <Paper style={{minHeight:'87vh', maxHeight:'87vh', overflow: 'auto'}}>
                <List
                sx={{
                    scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '8px',
          display: 'block'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#555',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#f1f1f1',
        },
                }}
                >
                { clusterItems }
                </List>
            </Paper>
        </Grid>
    }, [jobs, viewData, setSearchParams, hidePointList]);

    return (
        <AppBarPage title={client?.name || 'Patients'}
            actions={<>
            <Tooltip id="tt-on-more" title="More Options">
                <IconButton color='inherit' onClick={onMoreClick}><MoreVert /></IconButton>
            </Tooltip>
        </>
        }>
            <Grid container padding='2px 8px 0 8px'>
                <Grid item xs={3}>
                <EnumField name='en-patient-page' label={'Patient View'} value={'Clusters'} 
                    onChange={(_,v) => {
                        loading.showLoading();
                        analytics.breadcrumb(`patients_${v}_view`, { clientId: clientId});
                        const vlc = v?.toLocaleLowerCase() || '';
                        if(vlc == 'classic') {
                            navigate(`/clients/${clientId}/patients` + location.search, {state:{prevPath:location.pathname + location.search}});
                        } else if(vlc == 'inline') {
                            navigate(`/clients/${clientId}/patientsLive` + location.search, {state:{prevPath:location.pathname + location.search}});
                        } else if(vlc == 'clusters') {
                            navigate(`/clients/${clientId}/patientsClusters`, {state:{prevPath:location.pathname + location.search}});
                        }
                    }}
                    options={['Classic','Inline', 'Clusters']} readOnly={false}/>

                    { clusterFilterList }
                </Grid>
                <Grid item xs={9}>
                    <Paper style={{minHeight:'87vh', maxHeight:'87vh', overflow: 'auto'}}>
                        <List>
                            {!peOpen && patientList}
                        </List>
                    </Paper>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '12px', flexGrow: 1 }}>
                    <Typography>{count + ' Patients'}</Typography>
                    {
                        count > 0 ? <Pagination
                            page={page}
                            count={Math.ceil(count / 10)}
                            onChange={onPageChange}
                            
                            hidden={count < 10}
                        /> : <></>
                    }
                    </Box>
                </Grid>
                </Grid>
            </Grid>
            {
                /*peOpen ? <PatientEditor
                    open={true} onClose={hidePE} onSave={onPatientSaved}
                    patient={patientToEdit} client={client} plans={plans}/>
                    : <></>*/

                peOpen ? <UCMEditor page={ucmPage}
                    open={true} onClose={hidePE} onSave={onPatientSaved}
                    patient={patientToEdit} client={client} plans={plans} patientId={patientToEdit?._id || ''}/>
                    : <></>
            }
            <Outlet />
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={onMenuClose}
                sx={{
                    '.MuiListItemText-root': {
                        paddingRight: '16px'
                    }
                }}
            >
                <MenuItem title='Roughly cluster visits' onClick={onClusterClick} >
                    <ListItemIcon><WorkspacesIcon /></ListItemIcon>
                    <ListItemText>Clusters</ListItemText>
                </MenuItem>
                <MenuItem title='Build a detailed schedule' onClick={onScheduleClick}>
                    <ListItemIcon><ScheduleIcon /></ListItemIcon>
                    <ListItemText>Schedule</ListItemText>
                </MenuItem>
            </Menu>
            {clOpen
                ? <ClusterDialog
                    open
                    onClose={() => {
                        hideCL();
                        (new SessionStorage<string>('jobMeta')).remove();
                        navigate(location.pathname + location.search, { replace: true });
                    }}
                    client={client}
                    plans={plans} />
                : ''
            }
            {scOpen
                ? <ScheduleDialog
                    open
                    onClose={hideSc}
                    jobs={jobs.filter(j => j.name == 'schedule') as ScheduleJob[]}
                    client={client}
                    plans={plans} />
                : ''}
            { a1dOpen ? assignConfirmSingleDialog : <></> }
            { /*aMdOpen ? assignConfirmQueryDialog : <></>*/ }
        </AppBarPage>
    );
}

export default PatientsClustersPage;
