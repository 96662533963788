import { useState, useCallback } from 'react';

export type UseChangeManager<T> = {
  changes: Record<string, Partial<T>>;
  setField: (id: string, updates: Partial<T>) => void;
  getField: <K extends keyof T>(id: string, key: K) => T[K] | undefined;
  hasField: <K extends keyof T>(id: string, key: K) => boolean;
  remove: (id:string) => void;
  contain: (id:string) => boolean;
  get: (id:string) => Partial<T>;
};

export function useChangeManager<T>(): UseChangeManager<T> {
  const [changes, setChangedObjects] = useState<Record<string, Partial<T>>>({});

  const setField = useCallback((id: string, updates: Partial<T>) => {
    setChangedObjects((prev) => ({
      ...prev,
      [id]: {
        ...(prev[id] || {}),
        ...updates,
      },
    }));
  }, []);

  const getField = useCallback(
    <K extends keyof T>(id: string, key: K): T[K] | undefined => {
      return changes[id]?.[key];
    },
    [changes]
  );

  const hasField = useCallback(
    <K extends keyof T>(id: string, key: K): boolean => {
      return changes[id]?.[key] !== undefined;
      //return Object.keys(changes[id] || {}).includes(key.toString());
    },
    [changes]
  );

  const remove = (id: string) => {
    setChangedObjects((prev) => {
      const { [id]: _, ...rest } = prev;
      return rest;
    });
  };

  const contain = (id: string) => {
    return Boolean(changes[id]);
  };

  const get = useCallback((id: string) => {
    if(changes[id] === undefined) {
      const val = {} as T;
      setChangedObjects((prev) => ({ ...prev, [id]: val, }));
      return val;
    }
    return changes[id];
  }, [changes]);

  return { get, remove, contain, changes, setField, getField, hasField };
}
