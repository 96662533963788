import React, { useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, 
    DialogTitle, Tooltip, PaperProps, List, ListItem, ListItemText, Box, 
    ListItemButton,
    Typography,
    Divider,
    Paper,
    ListItemIcon,
    IconButton,
    CircularProgress} from '@mui/material';
import SpinnerButton from './SpinnerButton';
import { AssignmentInfo, Client, CustomFields, DocumentInfo, Gender, Patient, PatientRoster, PatientStatus, PatientTrackables, Plan, TrackableMeasure, Visit, VisitStatus } from '../models/core';
import { API } from '../utils/Api';
import PatientEditorView from './patients/2_0/PatientEditorView';
import VisitEditorView from './patients/2_0/VisitEditorView';
import PersonIcon from '@mui/icons-material/Person';
import WarningIcon from '@mui/icons-material/Warning';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import MeasureEditorView, { MeasureEditorViewSection, MeasureEditorViewState, patientTrackablesToState } from './patients/2_0/MeasuresEditorView';
import useSnackbar from '../hooks/useSnackbar';
import AlertSnack from './ErrorSnack';
import { authDocumentInfo } from '../utils/ResourceUtils';
import { analytics } from '../utils/analytics/zipAnalytics';
import { useAuth } from '../hooks/useAuth';
import { DateTime } from 'luxon';
import HistoryIcon from '@mui/icons-material/History';
import AddIcon from '@mui/icons-material/Add';
import HistoryView from './patients/2_0/HistoryView';
import useDialog from '../hooks/useDialog';
import SimpleDialog from './dialogs/SimpleDialog';

interface LoadingOverlayProps { open: boolean; top?:string; left?:string; width?:string; height?:string; }

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ open, top, left, width, height }) => {
    return open ? (
        <Box
          sx={{
            position: 'absolute',
            top: top || 0,
            left: left || 0,
            width: width || '100%',
            height: height || '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1,
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
    ) : null;
};


export interface UCMEditorDialogText {
    tooltip?: string;
    text: string;
}

export interface UCMEditorProps extends DialogProps {
    open: boolean;
    page?:'patient'|'visits'|'history'
    client: Client;
    plans: Plan[];
    patient?: Patient|undefined|null,
    patientId?:string;
    visits?: Visit[],
    footer?: UCMEditorDialogText;
    readOnly?: boolean;
    submitText?: string;
    backdropClose?: boolean;
    dialogPaperProps?: PaperProps;
    onClose?: () => void;
    onSave?: () => void;
}

function getNewestDate(dates: (DateTime | null)[]) {
    const dateObjects = dates?.map(date => date ? date.toUnixInteger() : 0) || [];
    const idx = dateObjects.indexOf(Math.max(...dateObjects));
    return idx != -1 ? dates[idx] : undefined;
}

function formatDates(fields: CustomFields): CustomFields {
    return Object.fromEntries(Object.entries(fields).map(([k, v]) => [
        k,
        v instanceof Date
            ? (DateTime.fromJSDate(v).toISO({
                suppressMilliseconds: true, suppressSeconds: true, includeOffset: false
            }) || '')
            : v
    ]))
}

const UCMEditor = ({
    open,
    page,
    client,
    plans,
    patient,
    patientId,
    visits,
    footer,
    readOnly,
    onClose,
    onSave,
    submitText,
    backdropClose = true,
    dialogPaperProps,
    ...props
}: UCMEditorProps): JSX.Element => {
    const MAX_RETRY_ATTEMPTS = 3;
    const [saving, setSaving] = useState(false);
    const [selectedKey, _setSelectedKey] = useState<keyof typeof UCMListItem>(page || 'patient');
    
    const [patientChangesText, setPatientChangesText] = useState<string>('');
    const [activePatient, setActivePatient] = useState<Patient|null>(patient || null);
    const [activePatientId, setActivePatientId] = useState<string>(patientId || activePatient?._id || '');
    const [activePatientChanges, setActivePatientChanges] = useState<Partial<Patient>>({});
    const [unsyncedPatientChanges, setUnsyncedPatientChanges] = useState<Partial<Patient>>({});
    
    const [visitList, setVisitList] = useState(visits || null); // The list of available visits for the patient
    const [visitChanges, setVisitChanges] = useState<Record<string,Partial<Visit>>>({}); // The saved list of changes for the visit
    const [activeVisit, setActiveVisit] = useState(visits && visits.length > 1 ? visits[0] : null); // The current active visit
    const [unsyncedVisitChanges, setUnsyncedVisitChanges] = useState<Record<string, Partial<Visit>>>({}); // The pending changes for the active visit
    
    const [patientRosters, setPatientRosters] = useState<PatientRoster[]|null>(null);

    const [activeTrackables, setActiveTrackables] = useState<PatientTrackables>();
    const [syncedTrackableState, setSyncedTrackableState] = useState<MeasureEditorViewState>();
    const [unsyncedTrackableState, setUnsyncedTrackableState] = useState<MeasureEditorViewState>();

    const [contentLoading, setContentLoading] = useState(false);
    const [patientErrorStatus, setPatientErrorStatus] = useState<boolean>(false);
    const [pendingSnackError, setPendingSnackError] = useState<Error>();

    const contentPaperRef = useRef<HTMLDivElement>(null);
    const confirmVisitDialog = useDialog();
    const confirmFullSave = useDialog();
    const confirmCreatePatient = useDialog();

    const { showError: snackError, showSuccess:snackSuccess, ...snackProps } = useSnackbar();
    React.useEffect(() => {
        if(pendingSnackError) { snackError(pendingSnackError); setPendingSnackError(undefined) }
    }, [pendingSnackError, snackError])

    const auth = useAuth();

    const setSelectedKey = React.useCallback((key:(keyof typeof UCMListItem)) => {
        if(selectedKey) {
            if (contentPaperRef.current) {
                contentPaperRef.current.scrollTop = 0;
            }
            _setSelectedKey(key);
            setContentLoading(true);
        }
    }, [selectedKey])

    const fetchPatient = React.useCallback(async (pid:string, clientId:string) => {
        let retries = 0;
        while(retries < MAX_RETRY_ATTEMPTS) {
            try{
                const result = await API.getPatient(clientId, pid);
                if(result) {
                    setActivePatient(result)
                    break;
                }
                retries += 1;
            } catch(e) {
                retries += 1;
                console.error(e)
            }
        }
        if(retries >= MAX_RETRY_ATTEMPTS) {
            //  ERROR
            setPendingSnackError(new Error('Failed to retrieve Patient, try again in a few minutes'));
        }
    }, [setActivePatient])

    const fetchPatientRosters = React.useCallback(async (pid:string, clientId:string) => {
        let retries = 0;
        while(retries < MAX_RETRY_ATTEMPTS) {
            try{
                const result = await API.getRosters(clientId, pid);
                if(result) {
                    setPatientRosters(result.items || [])
                    break;
                }
                retries += 1;
            } catch(e) {
                retries += 1;
                console.error(e)
            }
        }
        if(retries >= MAX_RETRY_ATTEMPTS) {
            setPatientRosters(null);
            setPendingSnackError(new Error('Failed to retrieve Patient Rosters, try again in a few minutes'));
        }
    }, [setPatientRosters])


    const [triggerFetchVisits, setTFV] = useState(false);
    const [triggerFetchPatient, setTFP] = useState(false);

    React.useEffect(() => {
        _setSelectedKey(page || 'patient');
        setActivePatient(patient || null);

        const pid = patientId || patient?._id || '';
        setActivePatientId(pid);
        setVisitList(visits || null);
        setUnsyncedPatientChanges({});
        setUnsyncedVisitChanges({});
        setPatientRosters(null);
        setActiveVisit(visits && visits.length > 1 ? visits[0] : null);
        setActiveTrackables(undefined);
        setUnsyncedTrackableState(undefined);
        setSyncedTrackableState(undefined);

        if(!patient && pid != '') {
            setTFP(true);
        }
        if(page == 'visits' && !visits || visits?.length == 0) {
            setTFV(true);
        }
        
    },[page, patientId, client, patient, visits])

    const fetchVisits = React.useCallback(async (pid:string, clientId:string) => {
        let retries = 0;
        while(retries < MAX_RETRY_ATTEMPTS) {
            try{
                const result = await API.getVisits(clientId, pid);
                if(result) {
                    setVisitList(result.items)
                    setActiveVisit(result.items && result.items.length > 0 ? result.items[0] : null);
                    break;
                }
                retries += 1;
            } catch(e) {
                retries += 1;
                console.error(e)
            }
        }
        if(retries >= MAX_RETRY_ATTEMPTS) {
            //  ERROR
            setPendingSnackError(new Error('Failed to retrieve Visits, try again in a few minutes'));
        }
    }, [setActiveVisit, setVisitList])


    React.useEffect(() => {
        if(triggerFetchPatient) {
            setTFP(false);
            if(activePatientId != '') {
                fetchPatient(activePatientId,client._id);
            }   
        }
    }, [triggerFetchPatient, fetchPatient, activePatientId, client._id])

    React.useEffect(() => {
        if(triggerFetchVisits) {
            setTFV(false);
            if(activePatientId != '') {
                fetchVisits(activePatientId,client._id);
            }
        }
    }, [triggerFetchVisits, fetchVisits, activePatientId, client._id])

    const fetchTrackables = React.useCallback(async (pid:string, clientId:string) => {
        let retries = 0;
        while(retries < MAX_RETRY_ATTEMPTS) {
            try{
                const result = await API.getPatientTrackables(clientId, pid);
                if(result) {
                    if(result.items && result.items.length > 0) {
                        const state = result.items[0] || {};
                        setActiveTrackables(state)
                        setSyncedTrackableState(patientTrackablesToState(state));
                    } else {
                        setActiveTrackables({} as PatientTrackables)
                        setSyncedTrackableState({measuresUpdated:false,  diagnosisUpdated:false, chronicUpdated:false, 
                            suspectUpdated:false, chronic:[], diagnosis:[], suspect:[], measures:[] });
                    }
                    break;
                }
            } catch(e) {
                console.error(e)
            }
            retries += 1;
        }
        if(retries >= MAX_RETRY_ATTEMPTS) {
            //  ERROR
            setPendingSnackError(new Error('Failed to retrieve Trackables, try again in a few minutes'));
        }
    }, [setActiveTrackables])

    const setTrackableCallback = React.useCallback((state:MeasureEditorViewState) => {
        setUnsyncedTrackableState(state);
    }, [])

    const onPatientStateChange = React.useCallback((hasErrors:boolean, _patient:Patient, changes:Partial<Patient>) => {
        setPatientErrorStatus(hasErrors);
        setUnsyncedPatientChanges(changes);
    },[]);

    const onVisitStateChange = React.useCallback((visit:Visit, change:Partial<Visit>) => {
        setUnsyncedVisitChanges((prev) => ({ ...prev, [visit._id]: { ...(prev[visit._id] || {}), ...change, } }));
    },[])
    
    const syncPendingVisitChanges = React.useCallback((visitId:string, change?:Partial<Visit>) => {
        if(change) {
            setVisitChanges((prev) => ({ ...prev, [visitId]: { ...(prev[visitId] || {}), ...change, } }));
        }
    }, []);

    // Example key-value pairs for the list
    const UCMListItem = {
        patient: () => { return <PatientEditorView patient={activePatient} client={client} plans={plans} 
            patientState={activePatientChanges} onUpdateState={onPatientStateChange} />},
        visits: () => {
            return activeVisit && activePatient ? <VisitEditorView visit={activeVisit} 
                client={client} patient={activePatient} visitState={visitChanges[activeVisit._id]}
                onStateChange={onVisitStateChange} /> : <></>
            },
        conditions: () => {
            return syncedTrackableState || unsyncedTrackableState ? <MeasureEditorView title='Conditions'
                sections={[
                    MeasureEditorViewSection.Demographics,
                    MeasureEditorViewSection.Chronic,
                    MeasureEditorViewSection.Suspect,
                    MeasureEditorViewSection.Diagnosis,
                ]}
                initialState={syncedTrackableState}
                onStateUpdate={setTrackableCallback}
            /> : <></>
        },
        measures: () => {
            return syncedTrackableState || unsyncedTrackableState ? <MeasureEditorView
                title='Measures'
                sections={[
                    MeasureEditorViewSection.Demographics,
                    MeasureEditorViewSection.Measures,
                ]}
                initialState={syncedTrackableState}
                onStateUpdate={setTrackableCallback}
            /> : <></>
        },
        history: () => {return <HistoryView rosters={patientRosters||undefined} /> },
    };

    const syncChanges = () => {
        unsyncedTrackableState && setSyncedTrackableState(unsyncedTrackableState);
        activeVisit && syncPendingVisitChanges(activeVisit._id, unsyncedVisitChanges[activeVisit._id])
        unsyncedPatientChanges && setActivePatientChanges(unsyncedPatientChanges);
    };

    const onSaveChangesClick = async (save?:()=>void) => {
        try {
            syncChanges();
            setSaving(true);
            const vxc:Record<string,Partial<Visit>> = {};
            visitList?.forEach((v) => { vxc[v._id] = { ...(visitChanges[v._id] || {}), ...(unsyncedVisitChanges[v._id] || {}) }; })

            if (await doFullSave(client._id, activePatient || null, unsyncedPatientChanges || activePatientChanges,
                visitList || [], vxc, unsyncedTrackableState || syncedTrackableState || null)
            ) {
                save && save();
            }
        } finally {
            setSaving(false);
        }
    }

    const onSubmitClick = async () => {
        await onSaveChangesClick(onSave);
    };

    const handleDialogClose = (_event: object, reason: "backdropClick" | "escapeKeyDown") => {
        if (!backdropClose && reason === "backdropClick") return;
        onClose && onClose();
    };

    const handleListItemClick = (key: keyof typeof UCMListItem, newEntityId?:string) => {
        if(selectedKey == key && !newEntityId) {
            return;
        }

        //  Handle Cleanup
        if(selectedKey == 'measures' || selectedKey == 'conditions') {
            unsyncedTrackableState && setSyncedTrackableState(unsyncedTrackableState);
        } else if(selectedKey == 'visits') {
            newEntityId && activeVisit && syncPendingVisitChanges(activeVisit._id, unsyncedVisitChanges[activeVisit._id])
        } else if(selectedKey == 'patient') {
            setActivePatientChanges(unsyncedPatientChanges);
        }

        setSelectedKey(key);


        // Get new data if needed
        if(key == 'visits') {
            if(!visitList || visitList.length == 0) {
                fetchVisits(activePatient?._id || '', client._id);
            } else if(!activeVisit && !newEntityId) {
                setActiveVisit(visitList[0]);
            } else if(newEntityId) {
                setActiveVisit(visitList?.find((c) => c._id == newEntityId) || null)
            }
        } else if(key == 'measures' || key == 'conditions') {
            if(!activeTrackables) {
                fetchTrackables(activePatient?._id || '', client._id);
            }
        } else if(key == 'history') {
            if(!patientRosters) {
                fetchPatientRosters(activePatient?._id || '', client._id);
            }
        }
    };

    const patientHasChanges = React.useMemo(() => {
        const cm = unsyncedPatientChanges;
        if(!cm) {
            return false;
        }
        const ck = Object.keys(cm);
        let changeKeys = '';
        if(ck) {
            changeKeys = ck.join(',') || '';
        }
        setPatientChangesText(changeKeys);
        return changeKeys.length > 0;
    }, [unsyncedPatientChanges]);

    const visitHasChanged = React.useCallback((visitId:string) => {
        const cm = unsyncedVisitChanges[visitId] || visitChanges[visitId];
        if(cm) {
            const cf = cm['customFields'];
            if(cf) {
                if(Object.keys(cf)?.length > 0) {
                    return true
                }
            }
            return Object.keys(cm)?.length > 1;
        }
        return false;
    }, [visitChanges, unsyncedVisitChanges]);

    const getVisitChanges = React.useCallback((visit:Partial<Visit>) => {
        if(visit) {
            const cf = visit['customFields'];
            return (cf && Object.keys(cf)?.join(',') || '') + (Object.keys(visit)?.filter((v) => v != 'customFields')?.join(',') || '');
        }
        return '';
    }, []);

    React.useEffect(() => {
        const interval = setInterval(() => {
          setContentLoading(false);
        }, 500);
        return () => clearInterval(interval);
    }, [contentLoading, selectedKey]);


    const createBlankVisit = React.useCallback(() => {
        return {
            status: VisitStatus.NEW, language: 'English', scheduled1: undefined, scheduled2: undefined,
            scheduled3: undefined, scheduled4: undefined, scheduledReason1: undefined, scheduledReason2: undefined,
            scheduledReason3: undefined, scheduledReason4: undefined, comments: undefined, clientNotes: undefined, clientDataEntry: undefined,
            appointmentId: undefined, customFields: undefined, numCalls: 0, numEmails: 0, numSMS: 0, numMails: 0,
            numContacts: 0, numReschedules : 0, refusalReason: undefined, phoneDate: undefined, emailDate: undefined, utrDate: undefined,
            scheduledDate: undefined, phoneOutcome: undefined, emailOutcome: undefined, appointmentOutcome: undefined, notes: undefined,
            memberInfoUpdate: undefined, info:authDocumentInfo(auth?.user,'Created New Visit'), lastContactDate: 'unknown'
        }
    }, [auth?.user])

    const saveVisit = React.useCallback(async (patientId:string, clientId:string, currentVisit:Visit|undefined|null, visiChanges:Partial<Visit>) => {
        if(!visiChanges) {
            console.error('No Changes Defined for Visit: ' + currentVisit?._id || 'unknown');
            return;
        }

        function getValue(key:keyof Visit, defaultValue:undefined|string|string[]|number|CustomFields|AssignmentInfo|boolean) {
            return visiChanges[key] !== undefined ? visiChanges[key] : defaultValue;
        }

        function fallbackString(value?:string) {
            return value ? '' : undefined;
        }
        
        //  TODO: Move to editor
        function findLastContactDate(fields: CustomFields) {
            const dateFields = ['Call Attempt 1','Call Attempt 2','Call Attempt 3','Call Attempt 4','Call Attempt 5','Call Attempt 6','Date of Text Attempt', 'Date of Email Attempt', 'Date of Mail Attempt'];
            const dateArray:DateTime[] = []
            dateFields.forEach(key => {
                const val = fields[key];
                if(val != undefined) {
                    if(val instanceof Date) {
                        const dtCheck = DateTime.fromJSDate(val);
                        dtCheck != undefined && dtCheck != null && dateArray.push(dtCheck);
                    } else if(typeof val === 'string') {
                        const dtCheck = DateTime.fromISO(val);
                        dtCheck != undefined && dtCheck != null && dateArray.push(dtCheck);
                    }
                }
            });

            return getNewestDate(dateArray);
        }

        const lastChanges = Object.keys(visiChanges || {}).reduce((p, c) => `${p+','+c}`);
        const info:DocumentInfo = authDocumentInfo(auth?.user, lastChanges);
        const customFields = {...currentVisit?.customFields, ...(getValue('customFields', {} as CustomFields) as CustomFields) };
        const lastContact = findLastContactDate(customFields);

        const stp = getValue('sentToPCP', currentVisit?.sentToPCP) as boolean || false;

        const scheduled1 = getValue('scheduled1', currentVisit?.scheduled1) as string || undefined;
        const scheduled2 = getValue('scheduled2', currentVisit?.scheduled2) as string || undefined;
        const scheduled3 = getValue('scheduled3', currentVisit?.scheduled3) as string || undefined;
        const scheduled4 = getValue('scheduled4', currentVisit?.scheduled4) as string || undefined;

        const newVisit = {
            status: getValue('status', currentVisit?.status + '') as VisitStatus,
            language: getValue('language', currentVisit?.language) as string || undefined,
            scheduled1: scheduled1 || '',
            scheduled2: scheduled2 || '',
            scheduled3: scheduled3 || '',
            scheduled4: scheduled4 || '',
            scheduledReason1: getValue('scheduledReason1', currentVisit?.scheduledReason1) as string || fallbackString(currentVisit?.scheduledReason1),
            scheduledReason2: getValue('scheduledReason2', currentVisit?.scheduledReason2) as string || fallbackString(currentVisit?.scheduledReason2),
            scheduledReason3: getValue('scheduledReason3', currentVisit?.scheduledReason3) as string || fallbackString(currentVisit?.scheduledReason3),
            scheduledReason4: getValue('scheduledReason4', currentVisit?.scheduledReason4) as string || fallbackString(currentVisit?.scheduledReason4),
            comments: getValue('comments', currentVisit?.comments) as string || fallbackString(currentVisit?.comments),
            sentToPCP: stp,
            clientNotes: getValue('clientNotes', currentVisit?.clientNotes) as string || fallbackString(currentVisit?.clientNotes),
            clientDataEntry: getValue('clientDataEntry', currentVisit?.clientDataEntry) as string || fallbackString(currentVisit?.clientDataEntry),
            appointmentId: getValue('appointmentId', currentVisit?.appointmentId) as number || undefined,
            customFields: formatDates(customFields),
            numCalls: getValue('numCalls', currentVisit?.numCalls) as number || 0,
            numEmails: getValue('numEmails', currentVisit?.numEmails) as number || 0,
            numSMS: getValue('numSMS', currentVisit?.numSMS) as number || 0,
            numMails: getValue('numMails', currentVisit?.numMails) as number || 0,
            numContacts: getValue('numContacts', currentVisit?.numContacts) as number || 0,
            numReschedules: getValue('numReschedules', currentVisit?.numReschedules) as number || 0,
            refusalReason: getValue('refusalReason', currentVisit?.refusalReason) as string || fallbackString(currentVisit?.refusalReason),
            phoneDate: getValue('phoneDate', currentVisit?.phoneDate) as string || fallbackString(currentVisit?.phoneDate),
            emailDate: getValue('emailDate', currentVisit?.emailDate) as string || fallbackString(currentVisit?.emailDate),
            utrDate: getValue('utrDate', currentVisit?.utrDate) as string || fallbackString(currentVisit?.utrDate),
            scheduledDate: getNewestDate([
                DateTime.fromISO(scheduled1||''),
                DateTime.fromISO(scheduled2||''),
                DateTime.fromISO(scheduled3||''),
                DateTime.fromISO(scheduled4||'')])?.toISODate() || undefined,
            phoneOutcome: getValue('phoneOutcome', currentVisit?.phoneOutcome) as string || fallbackString(currentVisit?.phoneOutcome),
            emailOutcome: getValue('emailOutcome', currentVisit?.emailOutcome) as string || fallbackString(currentVisit?.emailOutcome),
            appointmentOutcome: getValue('appointmentOutcome', currentVisit?.appointmentOutcome) as string || fallbackString(currentVisit?.appointmentOutcome),
            notes: getValue('notes', currentVisit?.notes) as string || fallbackString(currentVisit?.notes),
            memberInfoUpdate: getValue('memberInfoUpdate', currentVisit?.memberInfoUpdate) as string || fallbackString(currentVisit?.memberInfoUpdate),
            info: info,
            visitReasons: getValue('visitReasons', currentVisit?.visitReasons) as string[] || [],
            lastContactDate: lastContact?.toISO({ suppressMilliseconds: true, suppressSeconds: true, includeOffset: false }) || 'unknown'
        };
        analytics.track(currentVisit ? 'visit_update' : 'visit_create', {
            clientId: clientId,
            patientId: patientId,
            changes: lastChanges
        }, true);

        console.log({ ...(currentVisit as Visit), ...newVisit });
        if (currentVisit) {
            await API.updateVisit({ ...(currentVisit as Visit), ...newVisit });
        }
        else {
            await API.createVisit({ clientId, patientId, refId:`visit-${patientId}-${(visitList?.length || 0) + 1}`, ...newVisit });
        }
    },[auth?.user, visitList?.length]);

    const createVisit = React.useCallback(async (patientId:string, clientId:string) => {
        const vs = createBlankVisit() as Partial<Visit>;
        await saveVisit(patientId, clientId, null, vs);
        await fetchVisits(patientId, clientId);
    }, [fetchVisits, saveVisit, createBlankVisit])


    const savePatient = React.useCallback(async (currentPatient:Patient|undefined|null, changes:Partial<Patient>) => {
        if(!patientHasChanges || patientErrorStatus) {
            setPendingSnackError(new Error('Errors in Patients Data. Fix the Errors before Saving.'));
            return null;
        }

        let pid = currentPatient?._id || '';
        function getValue(key:keyof Patient, defaultValue:undefined|string|string[]|number|CustomFields|AssignmentInfo) {
            return changes[key] ? changes[key] : defaultValue;
        }

        const refId = getValue('refId', currentPatient?.refId) as string || '';

        let invalidPatient = refId == '';
        if (!currentPatient && !invalidPatient) {
            invalidPatient = (await API.getPatients(client._id, [['refId',refId]]))?.items?.length != 0;
            if(invalidPatient) {
                setPendingSnackError(new Error(`${refId} already exists for practice ${client.practiceId}`));
                return null;
            }
        }

        const info:DocumentInfo = authDocumentInfo(auth?.user, Object.keys(changes)?.reduce((p, c) => `${p+','+c}`));
        const assign = getValue('assignment', currentPatient?.assignment) as AssignmentInfo || { assignedBy:'', assignedDate:'', assignedTo:'None'};
        if(assign?.assignedTo != currentPatient?.assignment?.assignedTo) {
            analytics.track('assign', {  type:'single', currentPatient:currentPatient?._id || '',
                to:assign?.assignedTo || '',  by: auth?.user?.name || 'NA'
            }, true);
        }

        const customFields = {...currentPatient?.customFields, ...(getValue('customFields', {} as CustomFields) as CustomFields) };

        const newPatient = {
            refId: refId,
            status: getValue('status', currentPatient?.status ? currentPatient?.status + '' : undefined ) as PatientStatus || PatientStatus.ACTIVE,
            medicaidId: getValue('medicaidId', currentPatient?.medicaidId) as string,
            medicareId: getValue('medicareId', currentPatient?.medicareId) as string,
            lineOfBusiness: getValue('lineOfBusiness', currentPatient?.lineOfBusiness) as string,
            firstName: getValue('firstName', currentPatient?.firstName) as string,
            lastName: getValue('lastName', currentPatient?.lastName) as string,
            gender: getValue('gender', currentPatient?.gender ? currentPatient?.gender + '' : undefined) as Gender || undefined,
            dob: getValue('dob', currentPatient?.dob) as string,
            address: getValue('address', currentPatient?.address) as string,
            address2: getValue('address2', currentPatient?.address2) as string,
            city: getValue('city', currentPatient?.city) as string,
            county: getValue('county', currentPatient?.county) as string,
            state: getValue('state', currentPatient?.state) as string,
            zip: getValue('zip', currentPatient?.zip) as string,
            phone: getValue('phone', currentPatient?.phone) as string,
            phone2: getValue('phone2', currentPatient?.phone2) as string,
            email: getValue('email', currentPatient?.email) as string,
            guardianName: getValue('guardianName', currentPatient?.guardianName) as string,
            guardianPhone: getValue('guardianPhone', currentPatient?.guardianPhone) as string,
            guardianEmail: getValue('guardianEmail', currentPatient?.guardianEmail) as string,
            subProgram: getValue('subProgram', currentPatient?.subProgram) as string,
            pcpName: getValue('pcpName', currentPatient?.pcpName) as string,
            pcpPhone: getValue('pcpPhone', currentPatient?.pcpPhone) as string,
            pcpNPI: getValue('pcpNPI', currentPatient?.pcpNPI) as string,
            practiceName: getValue('practiceName', currentPatient?.practiceName) as string,
            groupName: getValue('groupName', currentPatient?.groupName) as string,
            caseManager: getValue('caseManager', currentPatient?.caseManager) as string,
            caseManagerPhone: getValue('caseManagerPhone', currentPatient?.caseManagerPhone) as string,
            language: getValue('language', currentPatient?.language) as string,
            risk: getValue('risk', currentPatient?.risk) as number,
            riskCategory: getValue('riskCategory', currentPatient?.riskCategory) as string,
            customFields: customFields,
            info,
            assignment: assign,
        };

        if (currentPatient && pid && pid != '') {
            const mpat = { ...currentPatient, ...newPatient };
            const rest = await API.updatePatient(mpat);
            analytics.track('patient_create', { clientId: client._id, patientId: pid, refId: refId }, true)
            if(!rest) {
                setPendingSnackError(new Error('Failed to save patient, try again in a few minutes.'));
                analytics.error('patient_update_error', { clientId: client._id, }, true);
                return null;
            }
            analytics.track('patient_update', { clientId: client._id, patientId: pid, refId: refId }, true)
        } else {
            const { acknowledged, insertedId } = await API.createPatient({ clientId: client._id, ...newPatient });
            if(!acknowledged) {
                setPendingSnackError(new Error('Failed to create patient, try again in a few minutes.'));
                analytics.error('patient_create_error', { clientId: client._id, }, true);
                return null;
            }
            pid = insertedId;
            analytics.track('patient_create', { clientId: client._id, patientId: pid, refId: refId }, true)

            await createVisit(pid, client._id);

            setActivePatient({ _id:pid, clientId: client._id, ...newPatient });
        }
        return pid;
    },[patientHasChanges, patientErrorStatus, auth?.user, client, createVisit])

    const saveVisitChangeChecked = React.useCallback(async (currentVisit:Visit|undefined|null, visitChanges:Partial<Visit>, patientId:string, clientId:string) => {
        if(!patientId) {
            setPendingSnackError(new Error('No Valid Patient Found'));
            return;
        }
        if(!visitHasChanged(currentVisit?._id || '')) {
            setPendingSnackError(new Error('No Changes Made or Error in the Visits Data'));
            return;
        }
        await saveVisit(patientId, clientId, currentVisit, visitChanges);
    }, [saveVisit, visitHasChanged]);

    const saveTrackables = React.useCallback(async (patientId:string, clientId:string, trackables:MeasureEditorViewState) => {
        if(!trackables || (!trackables?.chronicUpdated && !trackables?.diagnosisUpdated && !trackables.measuresUpdated && !trackables.suspectUpdated)) {
            return;
        }

        const trackableElements:TrackableMeasure[] = [
            ...(trackables?.chronic || []),
            ...(trackables?.diagnosis || []),
            ...(trackables?.measures || []),
            ...(trackables?.suspect || [])
        ];

        const trackInfo = authDocumentInfo(auth?.user,
            `chronic: ${trackables?.chronic?.length || 0} `
            + `suspect: ${trackables?.suspect?.length || 0} `
            + `diagnosis: ${trackables?.diagnosis?.length || 0} `
            + `measures: ${trackables?.measures?.length || 0}`);
        if(activeTrackables?._id) {
            await API.updatePatientTrackables({ ...activeTrackables, ...{trackable:trackableElements, info:trackInfo}});
            analytics.track('trackables_update', {  patientId:patientId || '' }, true);
        } else {
            await API.createPatientTrackables({ name:patientId, patientId:patientId,
                clientId:clientId, trackable:trackableElements, info:trackInfo});
            analytics.track('trackables_create', {  patientId:patientId || '' }, true);
        }
    },[activeTrackables, auth?.user]);

    const doFullSave = React.useCallback(async (clientId:string, patient:Patient|null, patientChanges:Partial<Patient>, 
        visits:Visit[]|null, visitChanges:{[k:string]:Partial<Visit>}, trackables:MeasureEditorViewState|null) => {
        
        let patientId = patient?._id || '';
        if(patientChanges && Object.keys(patientChanges).length > 0) {
            const ptr = await savePatient(patient, patientChanges);
            if(!ptr) {  return false;  }
            patientId = ptr;
        }

        if(patientId) {
            if(visitChanges && Object.keys(visitChanges).length > 0) {
                for (const ev of Object.entries(visitChanges)) {
                    const id = ev[0];
                    const vc = ev[1];
                    if(Object.keys(vc).length > 0) {
                        await saveVisit(patientId, clientId, visits?.find((v) => v._id === id), vc);
                    }
                }
            }

            if(trackables && (trackables?.chronicUpdated || trackables?.diagnosisUpdated 
                || trackables?.measuresUpdated || trackables?.suspectUpdated)) {
                await saveTrackables(patientId, clientId, trackables);
            }
        }
        return true;
    }, [saveVisit, savePatient, saveTrackables]);

    return (
        <Dialog
            {...props}
            open={open}
            onClose={handleDialogClose}
            PaperProps={dialogPaperProps || { sx: { height:'90%', maxHeight:'90%', width: '70%', maxWidth: '70%' } }}
        >
            <DialogTitle>UCMEditor</DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ width: '20%', borderRight: '1px solid #ccc', padding: '2px' }}>
                    <List>
                        <ListItem key={'ll-patient'} onClick={() => handleListItemClick('patient')} style={{padding:'2px 2px 2px 2px'}}>
                            <ListItemButton disableRipple selected={selectedKey == 'patient'} sx={{padding:'4px 4px 4px 4px', display:'flex'}}>
                                <ListItemIcon><PersonIcon/></ListItemIcon>
                                <ListItemText primary={<Typography variant='h5' component='div' 
                                    sx={{ justifyContent: 'left' }}>Patient</Typography> }/>
                                <Tooltip title={ 'Save Changes' + (patientChangesText == '' ? '' : ` [${patientChangesText}]`) }>
                                <span>
                                    {
                                        patientHasChanges ? <IconButton sx={{justifyContent:'right', color:(patientHasChanges ?
                                            (patientErrorStatus ? 'red' : undefined) : 'transparent')}}
                                            onClick={() => { savePatient(activePatient, unsyncedPatientChanges) } }>
                                            <WarningIcon/>
                                        </IconButton> : <></>
                                    }
                                </span>
                                </Tooltip>
                            </ListItemButton>
                        </ListItem>
                        <Divider/>
                        <ListItem key={'ll-visits'} onClick={() => handleListItemClick('visits')} style={{padding:'2px 2px 2px 2px'}}>
                            <ListItemButton disableRipple selected={selectedKey == 'visits'} sx={{padding:'4px 4px 4px 4px', display:'flex'}}
                                disabled={activePatient==null}>
                                <ListItemIcon><MedicalServicesOutlinedIcon/></ListItemIcon>
                                <ListItemText primary={<Typography variant='h5' component='div' 
                                    sx={{ justifyContent: 'left' }}>Visits</Typography> }/>
                                    <span>
                                    {
                                        <IconButton sx={{justifyContent:'right'}}
                                            onClick={confirmVisitDialog.show}>
                                            <AddIcon/>
                                        </IconButton>
                                    }
                                </span>
                            </ListItemButton>
                        </ListItem>
                        <Divider/>
                        {
                            //<Typography variant='h6' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>{/*`Visit: ${i + 1}`*/'Form Created'}</Typography> }
                            visitList && visitList.map((v) => {
                                const hasChanges = visitHasChanged(v._id);
                                const change = unsyncedVisitChanges[v._id] || visitChanges[v._id];
                                const changeString = getVisitChanges(change);
                                return <ListItem key={v._id} onClick={() => { handleListItemClick('visits', v._id); }} >
                                    <ListItemButton disableRipple selected={(selectedKey == 'visits' && activeVisit && activeVisit._id == v._id)|| false}
                                        disabled={activePatient==null}>
                                        <ListItemText primary={<b>Status {`${v.status || ''}`}</b>}
                                            secondary={
                                                <span>
                                                    Reason: { ( (v?._id && change && change['visitReasons'] !== undefined ? change['visitReasons']
                                                        : v?.visitReasons) as string[] || []).join(', ') }
                                                    <br/>
                                                    { v.created?.substring(0, v.created?.indexOf('T')) }
                                                </span>
                                            }/>
                                        <Tooltip title={'Save Changes' + (changeString == '' ? '' : ` [${changeString}]`)}>
                                            <span>
                                                {
                                                    hasChanges ? <IconButton sx={{justifyContent:'right', color:(hasChanges ? undefined : 'transparent')}}
                                                    onClick={() => { 
                                                        activeVisit && syncPendingVisitChanges(activeVisit?._id, unsyncedVisitChanges[activeVisit?._id]);
                                                            saveVisitChangeChecked(v, visitChanges[v._id], activePatient?._id || '', client._id)
                                                    }}>
                                                    <WarningIcon/>
                                                    </IconButton> : <></>
                                                }
                                            </span>
                                        </Tooltip>
                                    </ListItemButton>
                                </ListItem>
                            })
                        }
                        { visitList && visitList.length > 0 ? <Divider/> : <></> }
                        <ListItem key={'ll-conditions'} onClick={() => handleListItemClick('conditions')} style={{padding:'2px 2px 2px 2px'}}>
                            <ListItemButton disableRipple selected={selectedKey == 'conditions'} sx={{padding:'4px 4px 4px 4px', display:'flex'}}
                                disabled={activePatient==null}>
                                <ListItemIcon><PersonIcon/></ListItemIcon>
                                <ListItemText primary={<Typography variant='h5' component='div' 
                                    sx={{ justifyContent: 'left' }}>Conditions</Typography> }/>
                                <Tooltip title='Save Changes'>
                                <span>
                                    {
                                        unsyncedTrackableState?.diagnosisUpdated || unsyncedTrackableState?.suspectUpdated || unsyncedTrackableState?.chronicUpdated ?
                                        <IconButton sx={{justifyContent:'right', color:(
                                            unsyncedTrackableState?.diagnosisUpdated || unsyncedTrackableState?.suspectUpdated || unsyncedTrackableState?.chronicUpdated ? undefined : 'transparent') }}
                                            onClick={() => saveTrackables(patient?._id || '', client?._id, unsyncedTrackableState)}>
                                            <WarningIcon/>
                                        </IconButton>
                                        : <></>
                                    }
                                </span>
                                </Tooltip>
                            </ListItemButton>
                        </ListItem>
                        <Divider/>
                        <ListItem key={'ll-measures'} onClick={() => handleListItemClick('measures')} style={{padding:'2px 2px 2px 2px'}}>
                            <ListItemButton disableRipple selected={selectedKey == 'measures'} sx={{padding:'4px 4px 4px 4px', display:'flex'}}
                                disabled={activePatient==null}>
                                <ListItemIcon><PersonIcon/></ListItemIcon>
                                <ListItemText primary={<Typography variant='h5' component='div' 
                                    sx={{ justifyContent: 'left' }}>Measures</Typography> }/>
                                <Tooltip title='Save Changes'>
                                <span>
                                {
                                    unsyncedTrackableState?.measuresUpdated ?
                                    <IconButton sx={{justifyContent:'right', color:(unsyncedTrackableState?.measuresUpdated ? undefined : 'transparent') }}
                                        onClick={() => saveTrackables(patient?._id || '', client?._id, unsyncedTrackableState) }>
                                        <WarningIcon/>
                                    </IconButton>
                                    : <></>
                                }
                                </span>
                                </Tooltip>
                            </ListItemButton>
                        </ListItem>
                        <Divider/>

                        <ListItem key={'ll-history'} onClick={() => handleListItemClick('history')} style={{padding:'2px 2px 2px 2px'}}>
                            <ListItemButton disableRipple disabled={activePatient==null} selected={selectedKey == 'history'} sx={{padding:'4px 4px 4px 4px', display:'flex'}}>
                                <ListItemIcon><HistoryIcon/></ListItemIcon>
                                <ListItemText primary={<Typography variant='h5' component='div' 
                                    sx={{ justifyContent: 'left' }}>Rosters</Typography> }/>
                                <Tooltip title='Save Changes'>
                                <span>
                                    <IconButton sx={{justifyContent:'right', color: 'transparent' }}
                                        onClick={() => console.log('')}>
                                        <WarningIcon/>
                                    </IconButton>
                                </span>
                                </Tooltip>
                            </ListItemButton>
                        </ListItem>
                        <Divider/>
                    </List>
                </Box>

                <Box sx={{ width: '80%', padding: '16px' }}>
                    <Paper ref={contentPaperRef} sx={{ boxShadow: "none" }} 
                        style={{minHeight:'100%', maxHeight:'100%', maxWidth:'100%', overflow: 'auto', padding: '1p'}}>
                    {selectedKey ? (
                        <div>{UCMListItem[selectedKey]()}</div>
                    ) : (
                        <div>Select an item from the list</div>
                    )}
                    <LoadingOverlay open={contentLoading} 
                        top={`${contentPaperRef?.current?.parentElement?.offsetTop || 0}px`}
                        left={`${contentPaperRef?.current?.parentElement?.offsetLeft || 0}px`}
                        width={`${contentPaperRef?.current?.parentElement?.offsetWidth || 0}px`}
                        height={`${contentPaperRef?.current?.parentElement?.offsetHeight || 0}px`}
                    />
                    </Paper>
                </Box>
            </DialogContent>
            <DialogActions>
                {footer ? (
                    <>
                        <Tooltip title={footer.tooltip}>
                            <div>{footer.text}</div>
                        </Tooltip>
                        <div style={{ flex: '1 0 0' }} />
                    </>
                ) : null}
                {readOnly ? (
                    <Button onClick={onClose}>OK</Button>
                ) : (
                    <>
                        <Button onClick={onClose}>Close</Button>
                        <SpinnerButton onClick={ !activePatient && activePatientId == '' ?  confirmCreatePatient.show : confirmFullSave.show } showSpinner={saving}>
                            {submitText || (!activePatient && activePatientId == '') ? 'Create Patient' : 'Save'} 
                        </SpinnerButton>
                    </>
                )}
            </DialogActions>
            {
                confirmVisitDialog.open && <SimpleDialog open={confirmVisitDialog.open} title={'Create Visit'} 
                    body={`Do you want to create a new visit for patient: ${activePatient?.firstName} ${activePatient?.lastName}?`}
                    buttons={[
                        {text:'No', onClick:confirmVisitDialog.hide},
                        {text:'Yes', onClick:()=>{
                            createVisit(activePatient?._id || '', client?._id);
                            confirmVisitDialog.hide();
                        }}
                    ]}
                />
            }
            {
                confirmCreatePatient.open && <SimpleDialog open={confirmCreatePatient.open} title={'Create Patient'} 
                    body={`Do you want to create ${activePatient?.firstName} ${activePatient?.lastName}?`}
                    buttons={[
                        {text:'No', onClick:confirmCreatePatient.hide},
                        {text:'Create & Exit', onClick:()=>{
                            onSubmitClick();
                            confirmCreatePatient.hide();
                        }},
                        {text:'Create & Continue', onClick:() => {
                            onSaveChangesClick(() => { snackSuccess('Save Successfull'); });
                            confirmCreatePatient.hide();
                        }}
                    ]}
                />
            }
            {
                confirmFullSave.open && <SimpleDialog open={confirmFullSave.open} title={'Create Patient'} 
                    body={`Do you want save all changes for ${activePatient?.firstName} ${activePatient?.lastName}?`}
                    buttons={[
                        {text:'No', onClick:confirmFullSave.hide},
                        {text:'Save & Exit', onClick:()=>{
                            onSubmitClick();
                            confirmFullSave.hide();
                        }},
                        {text:'Save & Continue', onClick:() => {
                            onSaveChangesClick(() => { snackSuccess('Save Successfull'); });
                            confirmFullSave.hide();
                        }}
                    ]}
                />
            }
            <AlertSnack {...snackProps}/>
        </Dialog>
    );
};

export default UCMEditor;