import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { ListItemIcon, Avatar, ListItemText } from '@mui/material';
import Person3Icon from '@mui/icons-material/Person3';
import Person4Icon from '@mui/icons-material/Person4';
import { FilterField, FilterValue } from '../FiltersPanel';
import { CheckListItem } from './TransferList';
import { Office } from '../../models/ziphy';
import { API as ZiphyAPI } from '../../utils/ZiphyAPI';
import { useAuth } from '../../hooks/useAuth';
import SelectorTab from './SelectorTab';
import { Message } from './MessagesBox';
import { Agent } from '../../models/core';

interface AgentsTabProps {
    practiceId: number;
    messages?: Message[];
    value: Agent[];
    onChange?: (value: Agent[]) => void;
}

function officeAddress(office: Office | null): string {
    return office ? `${office.street} ${office.building}, ${office.city}, ${office.state}, ${office.zip}` : ''
}

function AgentsTab({ practiceId, messages, value, onChange }: AgentsTabProps): JSX.Element {
    const [offices, setOffices] = useState<Office[]>([]);
    const fields = useMemo<FilterField[]>(() => [
        { name: 'name', type: 'string', placeholder: 'Name', width: 4 },
        { name: 'office', type: 'enum', emptyLabel: 'Any Office', width: 3, items: offices.map(office => office.name || '') },
        { name: 'gender', type: 'enum', items: [['male', 'Male'], ['female', 'Female']], emptyLabel: 'Any Gender', width: 3 },
    ], [offices]);
    const defaultValue = useMemo(() => ({
        name: '', gender: ['all'], office: ['all']
    }), []);
    const itemFn = useCallback((item: Agent) => (
        <CheckListItem>
            <ListItemIcon>
                <Avatar>
                    {item.gender == 'male' ? <Person4Icon /> : <Person3Icon />}
                </Avatar>
            </ListItemIcon>
            <ListItemText
                primary={item.name}
                secondary={item.office}
            />
        </CheckListItem>
    ), []);

    const onFetch = useCallback(async (filter: FilterValue): Promise<Agent[]> => {
        const { expanded: { offices: ofcExp, users }, roles } = await ZiphyAPI.getRoles({
            and: [
                { contains: ['serviced_practice_ids', [practiceId] ]},
                { eq: ['is_active', true] },
                { eq: ['role', 'agent'] },
                ...((filter['office'] && !filter['office'].includes('all'))
                    ? [{
                        or: (filter['office'] as string[]).map<{ eq: [string, unknown] }>(v => ({
                            eq: ['office_id', offices.find(ofc => ofc.name == v)?.id || 0]
                        }))
                    }] : []),
                ...(!filter['name'].includes('all')
                    ? [{ imatch: ['name', filter['name'] as string] as [string, unknown] }]
                    : [])
            ]
        });
        return roles.items.map<Agent>(role => {
            const office = ofcExp && ofcExp.items.find(({ key }) => key == role.office_id)?.value || null;
            return ({
                id: role.id + '',
                name: `${role.first_name} ${role.last_name}`,
                age: 50, // todo: agent age
                gender: users.items.find(({ key }) => key == role.user_id)?.value.gender || 'unknown',
                language: 'english', // todo: agent language
                address: officeAddress(office),
                office: office?.name || ''
            });
        }).filter(r => !filter['gender'] || filter['gender'].includes('all') || (filter['gender'] as string[]).includes(r.gender || ''))
    }, [practiceId, offices]);

    const eqFn = useCallback((x: Agent, y: Agent) => (x.id == y.id), []);

    React.useEffect(() => {
        const fetch = async () => {
            const offices = await ZiphyAPI.getOffices({ eq: ['practice_id', practiceId] });
            setOffices(offices.offices.items);
        };
        fetch().catch(console.error);
    }, [practiceId])

    return <>
        <SelectorTab
            choosenItems={value}
            id='agents'
            {...{ fields, defaultValue, messages, onFetch, itemFn, eqFn, onChange }}
        />
    </>
}

export default AgentsTab;